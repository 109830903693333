function loadTawk(currentUser, url, embedId = null) {
  // clear Tawk_API instance
  window.Tawk_API = {}
  if (embedId) {
    window.Tawk_API.embedded = embedId
  }
  window.Tawk_LoadStart = new Date()
  window.Tawk_API.onLoad = function () {
    window.Tawk_API.maximize()
    window.Tawk_API.setAttributes({
      name: currentUser.fullName,
      email: currentUser.email,
    })
  }

  // remove Tawk window attributes
  // delete window.$_Tawk_AccountKey
  delete window.$_Tawk_WidgetId
  delete window.$_Tawk_Unstable
  delete window.$_Tawk

  // the div element that contains the chat UI has ID in the format of <random-string>-<current time in integer format>
  // we could take the first 3 digits of the current time to reverse find the div
  // its not possible that the first 3 digits change between the time that Tawk loads and when this script runs
  //
  // we can also take the first 4 digits, as new Date(1607000000000) and new Date(1608000000000) has
  // approx. 12 days difference, and user is not likely to stay on the app for this long.
  var str = new Date().getTime().toString().slice(0, 3)
  var selector = `div[id*="${str}"]`

  // removes the div element that contains the chat UI
  var div = document.querySelector(selector)
  if (div) div.remove()

  // removes the iframe injected by tawk
  var iframe = document.querySelector('iframe[title="chat widget logging"]')
  if (iframe) iframe.remove()

  var body = document.body
  var script = document.createElement('script')
  script.async = true
  script.src = url
  script.charset = 'UTF-8'
  script.setAttribute('crossorigin', '*')
  body.append(script)
}

export { loadTawk }
