import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import Amplitude from '../../utils/amplitudeHelper'
import LoadIcon from '../../components/LoadIcon'
import SideAgenda from '../../components/SideAgenda.js'
import { muteMusic, unmuteMusic } from '../../ducks/layout'
import openTheatre from '../../images/mobile/open-theatre.png'
import closeTheatre from '../../images/mobile/close-theatre.png'
import screen from '../../images/mobile/screen.png'
import config from '../../config'
import routes from '../../routes'
import { useObject } from 'react-firebase-hooks/database'
import { AccessDb } from '../../firebaseConfig'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { startTrack, endTrack } from '../../utils/timerHelper'
import { usePageActiveUserCount } from '../../utils/activeUserCount'

const MobileKFair20 = ({ currentUser, liveUrl }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [sidebar, setSidebar] = useState(false)
  const [theatre, setTheatre] = useState(false)
  const [snapshot, loading, error] = useObject(AccessDb)
  const dispatch = useDispatch()
  const history = useHistory()

  // usePageActiveUserCount(currentUser)
  
  useEffect(() => {
    if (currentUser.id){
      startTrack(currentUser)
    }
    
    return () => {
      if (currentUser.id){
        endTrack(currentUser)
      }
    }
  }, [currentUser.id])

  useEffect(() => {
    if (!loading) {
      if (!snapshot.val()) {
        history.push(routes.lobby)
        toast('Auditorium Hall currently unavailable.')
      } else {
        Amplitude.visit('Auditorium')
      }
    }
  }, [loading, snapshot, currentUser.id])

  useEffect(() => {
  }, [currentUser.id])

  useEffect(() => {
    dispatch(muteMusic())

    return () => {
      if (localStorage.getItem('muted') === 'false') {
        dispatch(unmuteMusic())
      }
    }
  }, [dispatch])

  const toggleSidebar = () => {
    if (sidebar === false) {
      Amplitude.clickCheckIn()
    }
    setSidebar(!sidebar)
  }

  const videoContainerTheatreStyle = {
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'black',
    zIndex: '99',
  }

  if (loading || error || !snapshot.val()) {
    return <LoadIcon />
  }
  return (
    <Page>
      <SideAgenda
        open={sidebar}
        toggleSidebar={toggleSidebar}
        hallNumber={1}
        currentUser={currentUser}
      />

      <div className="outer-wrapper">
        <div className="container">
          <div className="checkin-container">
            <button className="checkin-icon" onClick={toggleSidebar}>
              <small>Check-in</small>
              <svg width="24" height="24" viewBox="0 0 24 24">
                <path d="M0,0H24V24H0Z" fill="none"/>
                <path d="M19,3H14.82A2.988,2.988,0,0,0,9.18,3H5A2.006,2.006,0,0,0,3,5V19a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V5A2.006,2.006,0,0,0,19,3ZM12,3a1,1,0,1,1-1,1A1,1,0,0,1,12,3ZM9.29,16.29,6.7,13.7a1,1,0,0,1,1.41-1.41L10,14.17l5.88-5.88A1,1,0,1,1,17.29,9.7L10.7,16.29a1,1,0,0,1-1.41,0Z" fill="#f8f4ff"/>
              </svg>
            </button>
          </div>

          <div className="screen-container">
            <img src={screen} className="screen" alt="screen" />
            <div
              className="video-container"
              style={theatre ? videoContainerTheatreStyle : {}}
            >
              {isLoading && <LoadIcon />}
              <iframe
                title="Live Broadcast"
                src={liveUrl}
                allow="autoplay; fullscreen"
                allowFullScreen
                frameBorder="0"
                onLoad={() => {
                  setIsLoading(false)
                }}
              />
            </div>
          </div>

          <button
            type="button"
            className="theatre-button"
            onClick={() => setTheatre(true)}
          >
            <img src={openTheatre} className="theatre-button-icon" alt="theatre-icon" />
            <p className="theatre-button-label">Theatre mode</p>
          </button>

        </div>

        {/* <div className="chat-container">
          <iframe 
            title="slido" 
            src="https://app.sli.do/event/aig0jhpc" 
            height="100%" 
            width="100%" 
            frameBorder="0" 
            style={{minHeight: '560px'}}
          ></iframe>
        </div> */}

        {theatre && (
          <div className="theatre-close">
            <img
              src={closeTheatre}
              onClick={() => setTheatre(false)}
              className="theatre-close-icon"
              alt="close-icon"
            />
          </div>
        )}
      </div>
    </Page>
  )
}

const Page = styled.div`
  background: url(${config.assets.registration.portrait});
  background-size: cover;
  background-position: 50% 50%;
  height: calc(100% - 60px);
  width: 100vw;
  overflow-y: scroll;
  border-radius: 0px 0px 25px 25px;
  position: absolute;

  .outer-wrapper {
    width: 100%;
  }

  .container {
    width: 95%;
    margin: auto;
  }
  .checkin-icon {
    background: linear-gradient(#E20613, #312782);
    border-radius: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #f8f4ff;
    border: 2px solid #FFA700;
    padding: 12px 16px;
    width: 125px;
    small {
      font-weight: 500;
    }
  }
  .checkin-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 30px 0px 20px 0px;
  }

  .checkin {
    width: 38%;
    height: auto;
  }

  .screen-container {
    width: 100%;
    height: 0;
    padding-bottom: 58.5%;
    position: relative;
  }

  .screen {
    width: 100%;
    position: absolute;
  }

  .video-container {
    width: 92%;
    height: 81%;
    background: #1e1e1e;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50.5%, -51%);

    iframe {
      height: 100%;
      width: 100%;
    }
  }

  .title {
    width: 100%;
    margin: 20px 0px;
  }

  .chat-container {
    width: 100%;
    height: 500px;
    margin-top: 36px;
  }

  .theatre-button {
    width: 140px;
    height: 30px;
    background: black;
    margin: 10px 10px 0px 0px;
    float: right;
    border: none;
    border-radius: 5px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    opacity: 0.6;
    padding: 0;
  }

  .theatre-button-label {
    color: white;
    font-size: 12px;
    margin: 0px 10px 0px 0px;
  }

  .theatre-button-icon {
    width: 20px;
    margin: 0px 10px;
  }

  .theatre-close {
    color: white;
    background: black;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 99;
    opacity: 0.6;
  }

  .theatre-close-icon {
    width: 20px;
  }

  @media screen and (min-width: 540px) {
    .outer-wrapper {
      width: 540px;
      margin: auto;
    }
  }
`

export default MobileKFair20
