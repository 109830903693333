import React from 'react'
import { Modal } from 'react-bootstrap'


export default function InfographicModal({ posterOpen, hidePoster, content }) {
  return (
    <Modal
      show={posterOpen}
      onHide={hidePoster}
      aria-labelledby="poster-modal"
      centered
    > 
      <Modal.Body className="p-0">
        <img src={content} width="100%" alt="poster" />
      </Modal.Body>
    </Modal>
  )
}
