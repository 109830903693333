import React, { useEffect } from 'react'
import styled from 'styled-components'
import amplitude from 'amplitude-js'
import { Link } from 'react-router-dom'
import config from '../config'
import banner from '../images/backdrops/hall_banner.png'
import { useDispatch, useSelector } from 'react-redux'
import { getBooths } from '../ducks/booth'

const Page = styled.div`
  overflow: hidden;
  background-image: ${(props)=> `url(${props.hallBg})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  padding-top: 30px;

  #banner {
    position: fixed;
    height: 100%;
    width: 100%;
    img {
      width: 88%;
      max-height: 100%;
      display: block;
      margin: 0 auto;
    }
  }
  #hall {
    position: fixed;
    padding: 150px 30px;
    padding-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .booths {
    margin: 20px;
    margin-top: 20px;
    height: 27vh;
    -webkit-transition: margin 0.2s ease-out;
    -moz-transition: margin 0.2s ease-out;
    -o-transition: margin 0.2s ease-out;

    &:hover {
      cursor:pointer;
      margin-top: 5px;
    }
  }
`

export default function ExhibitionPage({
  hallBg,
  hallNumber,
  currentUser,
}) {

  const dispatch = useDispatch()
  const { booths } = useSelector((state) => state.booth)
  
  useEffect(() => {
    dispatch(getBooths(hallNumber))
  }, [hallNumber, dispatch])

  useEffect(() => {
    document.title = `${config.eventName} | Exhibition Hall`
  }, [])

  useEffect(() => {
    amplitude.getInstance().logEvent(`Visit Exhibition Hall`)
  }, [currentUser.id])

  return (
    <Page hallBg={hallBg} >
      <div id="banner">
        <img src={banner} alt="event-banner" />
      </div>
      <div id="hall">
        {booths.map((booth) => (
          <Link to={`/booths/${booth.id}`} key={`booth-${booth.id}`}>
            <img className="booths" src={booth.hall_image.url} alt="booth" />
          </Link>
        ))}
      </div>
    </Page>
  )
}
