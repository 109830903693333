import React from 'react'
import { Modal } from 'react-bootstrap'
import guide from '../../images/event/guide.jpg'
import { COLORS } from '../../styles/theme'
import styled from 'styled-components'

const StyledModal = styled(Modal)`
  .modal-content {
    /* border: 2px solid ${COLORS.primaryLight};
    box-shadow: 0px 4px 12px ${COLORS.lightGray1} ; */
  }
  .modal-body {
    padding: 0;
  }
`

export default function GuideModal({ showModal, hideSchedule }) {
  return (
    <StyledModal
      show={showModal}
      onHide={hideSchedule}
      size="xl"
      aria-labelledby="user-guide"
      centered
    >
      <Modal.Body>
        <img src={guide} width="100%" alt="user guide" onClick={hideSchedule} />
      </Modal.Body>
    </StyledModal>
  )
}
