import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import NotificationModal from './NotificationModal'
import ProgrammeModal from './ProgrammeModal'
import SpeakersModal from './SpeakersModal'
import AgendaModal from './AgendaModal'
import GuideModal from './GuideModal'

export default function ModalContainer({ currentUser }) {
  const { globalModal } = useSelector((state) => state.layout)
  const dispatch = useDispatch()

  const hideSchedule = () => {
    dispatch({
      type: 'HIDE_GLOBAL_MODAL',
    })
  }

  switch (globalModal) {
    case 'programme':
      return (
        <ProgrammeModal showModal={!!globalModal} hideSchedule={hideSchedule} currentUser={currentUser} />
      )
    case 'notifications':
      return (
        <NotificationModal showModal={!!globalModal} hideSchedule={hideSchedule} currentUser={currentUser} />
      )
    case 'speaker':
      return (
        <SpeakersModal showModal={!!globalModal} hideSchedule={hideSchedule} />
      )
    case 'agenda':
      return (
        <AgendaModal showModal={!!globalModal} hideSchedule={hideSchedule} />
      )

    case 'guide':
      return (
        <GuideModal showModal={!!globalModal} hideSchedule={hideSchedule} />
      )
    
    default:
      return <></>
  }
}
