import virtualIcon from '../images/icons/virtual.png'
import magazineIcon from '../images/icons/magazine.png'
import podcastIcon from '../images/icons/podcast.png'
import infographicsIcon from '../images/icons/infographics.png'
import financial from '../images/gallerium/financial.jpg'
import empower from '../images/gallerium/empower.jpg'
import building from '../images/gallerium/building.jpg'
import creativity from '../images/gallerium/creativity.jpg'

// infographics thumbnail
import bnm from '../images/gallerium/infographics/bnm/BNM.jpg'
import bnm1 from '../images/gallerium/infographics/bnm/ApaPerluAndaLakukanApabilaMembeliPolisiInsuransolehBankNegaraMalaysia.jpg'
import bnm2 from '../images/gallerium/infographics/bnm/ApakahPembahagianTerbaikBagiGajiBulananAndaolehBankNegaraMalaysia.jpg'
import bnm3 from '../images/gallerium/infographics/bnm/MengapaAndaPerlukanInsuransatauTakafulolehBankNegaraMalaysia.jpg'
// import bnm4 from '../images/gallerium/infographics/bnm/SudikahAndaMenjadiPenjaminolehBankNegaraMalaysia.jpg'
import kkm from '../images/gallerium/infographics/kkm/kkm.jpg'
import kkm1 from '../images/gallerium/infographics/kkm/JarakFizikalYangSelamatKetikaBeriadahatauBersenam.jpg'
import kkm2 from '../images/gallerium/infographics/kkm/GelangPengawasanDanPemantauanDiRumahHSO.jpg'
import kkm3 from '../images/gallerium/infographics/kkm/JarakSosialJikaBergejala.jpg'
import kkm4 from '../images/gallerium/infographics/kkm/JenisPelitupMuka.jpg'
import kwsp_trans from '../images/gallerium/infographics/kwsp/kwsp_logo.png'
import kwsp from '../images/gallerium/infographics/kwsp/KWSP.png'
import kwsp1 from '../images/gallerium/infographics/kwsp/Picture1.jpg'
import kwsp2 from '../images/gallerium/infographics/kwsp/Picture2.jpg'
import kwsp3 from '../images/gallerium/infographics/kwsp/Picture3.jpg'
import kwsp4 from '../images/gallerium/infographics/kwsp/Picture4.jpg'
import cr from '../images/gallerium/infographics/creativity&resilience/CreativityAndResilience.png'
import cr1 from '../images/gallerium/infographics/creativity&resilience/BeingPhysicallyActiveDuringCovid-19.jpg'
import cr2 from '../images/gallerium/infographics/creativity&resilience/6TipsForEmployeestoWorkFromHomeEfficiently.png'
import cr3 from '../images/gallerium/infographics/creativity&resilience/WellnessTipsSelfCareAtHome.jpg'
import cr4 from '../images/gallerium/infographics/creativity&resilience/PhysicalActivityRecommendationsDuringaPandemic.png'

// magazines thumbnail
import mag1 from '../images/gallerium/magazines/1MajalahLabur.JPG'
import mag2 from '../images/gallerium/magazines/2Ringgit.JPG'
import mag3 from '../images/gallerium/magazines/3Ikon.JPG'
import mag4 from '../images/gallerium/magazines/4MyHealth.JPG'
import mag5 from '../images/gallerium/magazines/5MyHealth.JPG'
import mag6 from '../images/gallerium/magazines/6MyHealth.JPG'
import mag7 from '../images/gallerium/magazines/7MyMoneyMatters-ENG.JPG'
import mag8 from '../images/gallerium/magazines/8MyMoneyMatters-BM.JPG'

// virtual gallery thumbnail
import virtual1 from '../images/gallerium/virtual/1BankNegaraMalaysia.jpg'
import virtual2 from '../images/gallerium/virtual/2NationalMuseumofMalaysia.png'
import virtual3 from '../images/gallerium/virtual/3DepartmentOfMuseumMalaysia.jpg'
import virtual4 from '../images/gallerium/virtual/4Google_Nasa_sAccessMars.JPG'
import virtual5 from '../images/gallerium/virtual/5TheHiddenWorldOfTheNationalParks.JPG'
import virtual6 from '../images/gallerium/virtual/6TheMuseumOfTheWorld.JPG'


export default [
  // onclick thumbnail => newpage with images
  {
    name: "Infographics",
    icon: infographicsIcon,
    destination: '/gallerium/infographics',
    materials: [
      {
        name: "KWSP",
        image: kwsp,
        image_trans: kwsp_trans,
        infographics: [
          kwsp1, kwsp2, kwsp3, kwsp4
        ]
      },
      {
        name: "Bank Negara Malaysia",
        image: bnm,
        infographics: [
          bnm1, bnm2, bnm3
        ]
      },
      {
        name: "Kementerian Kesihatan Malaysia",
        image: kkm,
        infographics: [
          kkm1, kkm2, kkm3, kkm4
        ]
      },
      {
        name: "Creativity And Resilience",
        image: cr,
        infographics: [
          cr1, cr2, cr3, cr4
        ]
      },
    ],
  },
  // onclick thumbnail => newpage with videos
  {
    name: "Videos & Podcast",
    icon: podcastIcon,
    destination: '/gallerium/videos-podcast',
    podcast: [
      {
        title: "What Next For Malaysia’s Economy?",
        link: "https://open.spotify.com/embed-podcast/episode/6H5ipBtLZ82Repwr4PQGrm",
      },
      {
        title: "How Will The CMCO Affect The Economy",
        link: "https://omny.fm/shows/eveningedition/how-will-the-cmco-affect-the-economy/embed",
      },
      {
        title: "Digitally – Savvy People",
        link: "https://open.spotify.com/embed?uri=spotify%3Aplaylist%3A6jCGIY6dR6TWnG1iUgJHok",
      },
      {
        title: "Digital – Innovation Investment",
        link: "https://open.spotify.com/embed?uri=spotify%3Aplaylist%3A0KaBLbdIlGPEj1kNEuucKx",
      },
    ],
    materials: [
      {
        name: "Financial Access",
        image: financial,
        video: [
          {
            title: "Perancangan Persaraan",
            link: "https://www.youtube.com/embed/-82FbVylBYk",
          },
          {
            title: "Kenapa Saham Tengah Trending Sekarang",
            link: "https://www.youtube.com/embed/L1kCdXfAFL8",
          },
          {
            title: "Adakah Kekayaan Boleh Membawa Kepada Kebahagiaan",
            link: "https://www.youtube.com/embed/94RP2E6QGV4",
          },
          {
            title: "Konsep Asas Ekonomi",
            link: "https://www.youtube.com/embed/umI5-7Z2IG0",
          },
        ]
      },
      {
        name: "Empowering Education",
        image: empower,
        video: [
          {
            title: "100 Syarikat Terbaik di Malaysia",
            link: "https://www.youtube.com/embed/Ezkx5mGeAnA",
          },
          {
            title: "Conflict of Interest Dalam Syarikat",
            link: "https://www.youtube.com/embed/UHq1v8cekcQ",
          },
          {
            title: "32 Clever Food Hacks to make in 5 Minutes",
            link: "https://www.youtube.com/embed/i2nfiD3pdVc",
          },
          {
            title: "Apa itu Artificial Intelligence",
            link: "https://www.youtube.com/embed/lTtfPglMT7g",
          },
        ]
      },
      {
        name: "Creativity And Resilience",
        image: creativity,
        video: [
          {
            title: "27 Plant And Garden Hacks",
            link: "https://www.youtube.com/embed/obCT76j8wFM",
          },
          {
            title: "31 Smart Parenting Hacks And Gadgets",
            link: "https://www.youtube.com/embed/bfLd-QJdp1s",
          },
          {
            title: "32 Clever Food Hacks to make in 5 Minutes",
            link: "https://www.youtube.com/embed/Cwn7gZNew_c",
          },
          {
            title: "30 Home Decor Hack",
            link: "https://www.youtube.com/embed/JrI8-6TFT3I",
          },
        ]
      },
      {
        name: "Building Home Economy",
        image: building,
        video: [
          {
            title: "Surviving the storm in Malaysia - World Bank",
            link: "https://www.youtube.com/embed/rcqHQmcWhpk",
          },
          {
            title: "BNM: Economic growth to improve in 2H20",
            link: "https://www.youtube.com/embed/FLa1HJoY9F0",
          },
          {
            title: "Covid-19: how it will change the world - The Economists",
            link: "https://www.youtube.com/embed/XsArdIXgRTU",
          },
          {
            title: "Covid-19: how to fix the economy - The Economist",
            link: "https://www.youtube.com/embed/p0tCPwyJ6JI",
          },
        ]
      },
    ],
  },
  // onclick thumbnail open link in new tab
  {
    name: "Magazines",
    icon: magazineIcon,
    destination: '/gallerium/magazines',
    materials: [
      {
        image: mag7,
        link: "https://drive.google.com/file/d/1sXbpzsuKuvF4lH_JyEJD8Q9nxFuUUrbY/view?usp=sharing",
      },
      {
        image: mag8,
        link: "https://drive.google.com/file/d/13a-YiugNtfgZBuTjRUKeSJmd6M3jjQIR/view?usp=sharing",
      },
      {
        image: mag1,
        link: "https://www.majalahlabur.com/",
      },
      {
        image: mag2,
        link: "https://www.bnm.gov.my/index.php?ch=35&pg=143&ac=115&bb=file&en",
      },
      {
        image: mag3,
        link: "https://ikonmalaysia.com/e-magazine/",
      },
      {
        image: mag4,
        link: "https://www.infosihat.gov.my/images/media_sihat/emagazine/Mental/mobile/index.html",
      },
      {
        image: mag5,
        link: "https://www.infosihat.gov.my/images/media_sihat/emagazine/COVID/mobile/index.html",
      },
      {
        image: mag6,
        link: "https://www.infosihat.gov.my/images/media_sihat/emagazine/LET'S%20TALK%20MINDA%20SIHAT/mobile/index.html",
      },
    ],
  },
  // onclick thumbnail open link in new tab
  {
    name: "Virtual Gallery",
    icon: virtualIcon,
    destination: '/gallerium/virtual-gallery',
    materials: [
      {
        name: "Bank Negara Malaysia Virtual Gallery",
        image: virtual1,
        link: "http://www.museum.bnm.gov.my/v2/virtualmuseum/",
      },
      {
        name: "National Museum Of Malaysia",
        image: virtual2,
        link: "https://historyview.org/library/national-museum-malaysia/",
      },
      {
        name: "Department Of Museum Malaysia Virtual Gallery",
        image: virtual3,
        link: "http://galerimaya.jmm.gov.my/thelostkingdoms",
      },
      {
        name: "Google & Nasa's Access Mars",
        image: virtual4,
        link: "https://accessmars.withgoogle.com/",
      },
      {
        name: "The Hidden World Of The National Parks",
        image: virtual5,
        link: "https://artsandculture.withgoogle.com/en-us/national-parks-service/parks",
      },
      {
        name: "The Museum Of The World",
        image: virtual6,
        link: "https://britishmuseum.withgoogle.com/",
      },
    ],
  },
]

