import React from 'react'
import styled from 'styled-components'
import routes from '../routes'
import { COLORS } from '../styles/theme'
import { firebaseDb } from '../firebaseConfig'
import { useList } from 'react-firebase-hooks/database'
import LoadIcon from '../components/LoadIcon'

const Page = styled.div`
  background: ${COLORS.primary};
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  /* align-items: center; */

  #board {
    background-color: white;
    border-radius: 24px;
    padding: 48px 32px;
    margin-top: 50px;
    width: 35%;
    height: 35%;
    h4 {
      text-decoration: underline;
    }
    span {
      background-color: red;
      color: white;
      border-radius: 4px;
      padding: 4px;
    }
  }
`

export default function ActiveCountPage() {
  const [lobbySnapshots, lobbyLoading, lobbyError] = useList(firebaseDb.ref('pageActiveUserCount'+'/'+ routes.lobby))
  const [audSnapshots, audLoading, audError] = useList(firebaseDb.ref('pageActiveUserCount'+'/'+ routes.auditorium1))

  
  if (lobbyLoading || audLoading){
    return <LoadIcon />
  }
  return (
    <Page>
      <div id="board">
        <h4>Active Users In Hall</h4>
        <h5>Lobby : <span>{lobbySnapshots.length}</span></h5>
        <h5>Auditorium : <span>{audSnapshots.length}</span></h5>
      </div>
    </Page>
  )
}
