
const getButtonPos = (boothType) => {
  switch (boothType){
    case 1:
      return {
        // vid: `
        //   top: 6%;
        //   left: 0.3%;
        // `,
        image: `
          top: 6%;
          left: 0.3%;
        `,
        chat: `
          top: 6%;
          left: 16.8%;
        `,
        pdf: `
          top: 6%;
          left: 8.5%;
        `,
        info: `
          top: 6%;
          left: 42%;
        `,
        namecard: `
          top: 6%;
          left: 25.2%;
        `,
      }
    case 2:
      return {
        vid: `
          top: 6%;
          left: 77.2%;
        `,
        image: `
          top: 6%;
          left: 62%;
        `,
        chat: `
          top: 6%;
          left: 85%;
        `,
        pdf: `
          top: 6%;
          left: 69.6%;
        `,
        info: `
          top: 6%;
          left: 40%;
        `,
        namecard: `
          top: 6%;
          left: 92.5%;
        `,
      }
    case 3:
      return {
        vid: `
          top: 6%;
          left: 15.1%;
        `,
        image2: `
          top: 6%;
          left: 0%;
        `,
        image: `
          top: 6%;
          left: 7.7%;
        `,
        game: `
          top: 6%;
          left: 22.8%;
        `,
        info: `
          top: 6%;
          left: 42%;
        `,
        namecard: `
          top: 6%;
          left: 30.5%;
        `,
      }
    case 4:
      return {
        vid: `
          top: 46%;
          left: 69%;
        `,
        image: `
          top: 50%;
          left: 35%;
        `,
        chat: `
          top: 71.5%;
          left: 66%;
        `,
        pdf: `
          top: 69%;
          left: 51%;
        `,
        info: `
          top: 18%;
          left: 63%;
        `,
        zoom: `
          top: 82.5%;
          left: 72.5%;
        `,
      }
    case 5:
      return {
        vid: `
          top: 48%;
          left: 65.5%;
        `,
        image: `
          top: 50%;
          left: 32%;
        `,
        chat: `
          top: 71.5%;
          left: 66%;
        `,
        pdf: `
          top: 65.5%;
          left: 48.5%;
        `,
        info: `
          top: 18%;
          left: 43%;
        `,
      }
    default:
      return {}
  }
}
export { getButtonPos };