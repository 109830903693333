import React, { useState } from 'react'
import styled from 'styled-components'
import pinIcon from '../images/icons/pinIcon.png'
import checkIn from '../images/icons/checkin.png'
import { toast } from 'react-toastify'
import Axios from 'axios'
import config from '../config'
import { COLORS } from '../styles/theme'

const StyledAgenda = styled.div`
  .agenda-item {
    color: #3C1E5D;
    .end {
      color: #000000;
    }
    .attend,
    .replay {
      border: none;
      background: transparent;
      height: 30px;
      width: 50px;
      margin: 0;
      p.load {
        margin: 0;
        font-weight: 500;
        color: #3C1E5D;
        font-size: 8px;
      }
      img {
        height: 100%;
      }
      svg.pending {
        transform: scale(0.6);
      }
      .icon-label {
        color: #3C1E5D;
        /* color: ${COLORS.primary}; */
        text-align: center;
        font-size: 6.5px;
        opacity: 1;
      }
      &:hover {
        svg.pending {
          transform: scale(1);
        }
        .icon-label {
          font-size: 1px;
          opacity: 0;
        }
      }
    }
  }

  .buttons-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
`

export default function AuditoriumAgendaItem({
  agenda,
  agendaList,
  setAgendaList,
  currentUser,
}) {
  const [isLoading, setIsLoading] = useState(false)

  const takeAttendance = (agenda) => {
    if (agendaList.filter((item) => item.id === agenda.id)[0].attendance === true)
      return
    setIsLoading(true)
    Axios({
      method: 'post',
      url: `${config.apiUrl}/api/v1/hall_sessions/${agenda.id}/check_in`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('jwt_token')}`,
      },
    })
      .then((response) => {
        setIsLoading(false)
        toast(`Check-in successful.`)
        const newAgendaList = agendaList.map((item, index) => ({
          ...item,
          attendance: item.id === agenda.id ? true : item.attendance,
        }))
        setAgendaList(newAgendaList)
      })
      .catch((err) => {
        setIsLoading(false)
        console.error(err.response.data.error);
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.error
        ) {
          toast(err.response.data.error)
        } else {
          toast('Check-in failed. Something went wrong')
        }
      })
  }

  return (
    <StyledAgenda>
      <div key={agenda.id} className="d-flex agenda-item my-4">
        <div className="w-25">
          <small className="d-block start">{agenda.start}</small>
          <small className="d-block end">{agenda.end}</small>
        </div>
        <small
          className="d-block w-50"
          dangerouslySetInnerHTML={{ __html: agenda.desc }}
        />
        <div className="w-25 buttons-container">
          <button
            className="attend"
            onClick={() => takeAttendance(agenda)}
            disabled={isLoading || agenda.attendance}
          >
            {isLoading ? (
              <p className="load">Loading</p>
            ) : (
              <>
                {
                  agenda.attendance ?
                  <svg className="checked" width="28" height="30.086" viewBox="0 0 28 30.086">
                    <text transform="translate(0 28.086)" fill="#3C1E5D" fontSize="6" fontFamily="Montserrat-SemiBold, Montserrat" fontWeight="600"><tspan x="0" y="0">Check-in</tspan></text>
                    <path d="M99.86-81.8a2.783,2.783,0,0,1-2.027-.871l-6.685-7.034a2.8,2.8,0,0,1,.1-3.951,2.794,2.794,0,0,1,3.952.1l4.815,5.067,13.7-12.259a2.8,2.8,0,0,1,3.947.22,2.8,2.8,0,0,1-.22,3.949L101.723-82.514A2.784,2.784,0,0,1,99.86-81.8Z" transform="translate(-90.38 101.459)" fill="#3C1E5D"/>
                  </svg>
                  :
                  <svg className="pending" width="19.458" height="28.095" viewBox="0 0 19.458 28.095">
                    <g transform="translate(-8.386)">
                      <g transform="translate(8.386)">
                        <path d="M101.008-112.281a9.728,9.728,0,0,0-9.73,9.728c0,4.664,3.294,12.426,7.669,17.4a2.828,2.828,0,0,0,4.312-.067c3.567-4.31,7.477-12.929,7.477-17.335A9.728,9.728,0,0,0,101.008-112.281Zm0,13.919a3.891,3.891,0,0,1-3.892-3.892,3.891,3.891,0,0,1,3.892-3.892,3.891,3.891,0,0,1,3.89,3.892A3.891,3.891,0,0,1,101.008-98.363Z" transform="translate(-91.278 112.281)" fill="#3C1E5D"/>
                      </g>
                    </g>
                  </svg>
                }
                <small
                  className={`icon-label ${
                    agenda.attendance ? `d-none` : `d-block`
                  }`}
                >
                  Check-in
                </small>
              </>
            )}
          </button>
        </div>
      </div>
    </StyledAgenda>
  )
}
