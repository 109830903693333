import React from 'react'
import { Modal } from 'react-bootstrap'
import speaker from '../../images/event/EMC-speaker.jpg'
import { COLORS } from '../../styles/theme'
import styled from 'styled-components'

const StyledModal = styled(Modal)`
  .modal-content {
    border: 2px solid ${COLORS.primaryLight};
    box-shadow: 0px 4px 12px ${COLORS.lightGray1} ;
  }
  .modal-body {
    padding: 0;
  }
`

export default function SpeakersModal({ showModal, hideSchedule }) {
  return (
    <StyledModal
      show={showModal}
      onHide={hideSchedule}
      size="lg"
      aria-labelledby="speakers-bio"
      centered
    >
      <Modal.Body>
        <img src={speaker} width="100%" alt="EMC speaker-bio"/>
      </Modal.Body>
    </StyledModal>
  )
}
