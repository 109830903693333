import { firebaseDb } from "../firebaseConfig"
import { useEffect } from 'react'

const usePageActiveUserCount = (currentUser) => {
  const currentPagePath = window.location.pathname
  useEffect(() => {
    firebaseDb.ref(`/pageActiveUserCount/${currentPagePath}/${currentUser?.id}`).push(Date.now().toString())
    
    return () => {
      firebaseDb.ref(`/pageActiveUserCount/${currentPagePath}/${currentUser?.id}`).remove()
    }
  }, [currentPagePath])
}

const deactivateUser = (currentUser) => {
  const currentPagePath = window.location.pathname
  firebaseDb.ref(`/pageActiveUserCount/${currentPagePath}/${currentUser?.id}`).remove()
}

export { usePageActiveUserCount, deactivateUser };

