import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Button } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'

import { COLORS, SIZES } from '../../styles/theme';
import config from '../../config'
import routes from '../../routes'
import banner from '../../images/registration/event-banner.jpg'
import BG from '../../images/registration/BG_mobile.jpg'
import logo from '../../images/registration/event-logo.png'
import { toast } from 'react-toastify';

const Page = styled.div`
  min-height: 100vh;
  height: 100%;
  width:100vw;
  background-image: url(${config.assets.registration.landscape});
  background-repeat: no-repeat;
  background-size: cover;

  ${SIZES.mobile} {
    font-size: 70%;
    background-image: url(${config.assets.registration.portrait});
    background-position: top center;
  }
  #content {
    background-image: url(${BG});
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    position: relative;
    img.mobile-hide {
      object-fit: cover;
      height: 100vh;
      object-position: top right;
    }
    img.mobile-show {
      display: none;
    }
    ${SIZES.mobile} {
      background: none;
      height: 100%;
      
      img.mobile-hide {
        display: none;
      }

      #header {
        /* background-image: url(${BG}); */
        /* background-size: cover; */
        min-height:50vh;
        height:50%;
      }
      img.mobile-show {
        display: block;
        margin: 0 auto;
      }
    }
  }
  #action-button {
    width: 50%; 
    ${SIZES.mobile} {
      width: 100%; 
    }
    a,
    button {
      background: ${COLORS.primary};
      border: 3px solid #AB8EC0;
      width: 25%;
      display: flex;
      justify-content: space-between;
      margin: 0px auto;
      font-size: 1.2em;
      color: #FFF;
      font-weight: 500;
      padding: 8px 30px;
      border-radius: 56px;
      font-style: italic ;
      position: absolute;
      bottom: 12vh;
      right: 150px;
      div {
        line-height: 32px;
      }
      div.arrow {
        line-height: 24px;
        font-size: 1.4em;
        align-self: flex-end;
      }
      ${SIZES.mobile} {
        margin: 36px auto;
        margin-bottom: 100px;
        font-size: 1.25em;
        width: 70%;
        position: static;
      }
    }
  }
`

export default function PreRegisterPage() {
  const history = useHistory()

  useEffect(() => {
    // history.push(routes.lobby)
    // toast("Registration has been closed.")
    document.title = `${config.eventName} | Registration`
  }, [])

  return (
    <Page>
      <div id="content">
        <img className="mobile-hide" src={config.assets.entrance.landscape} width="100%" alt="event-banner" />
        <div id="header">
          <img className="mobile-show" src={config.assets.entrance.landscape} width="100%" alt="event-banner" />
        </div>
        <div id="action-button">
          <Button variant="default" as={Link} to={routes.register}>
            <div>REGISTER HERE</div>
            <div className="arrow">⇀</div>
          </Button>
          {/* <img className="mobile-show" src={logo} width="65%" alt="event-logo" /> */}
        </div>
      </div>
    </Page>
  )
}