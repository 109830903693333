import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import config from '../config'
import { COLORS, SIZES } from '../styles/theme'
import banner from '../images/registration/event-banner.jpg'
import logo from '../images/registration/event-logo.png'
import BG from '../images/registration/BG_mobile.jpg'

const Page = styled.div`
  background: linear-gradient(#AB8EC0, #3C1E5D);

  min-height: 100vh;
  width: 100vw;
  text-align: center;
  
  #content {
    position: absolute;
    top: 0;
    width:100%;
  }

  #header {
    background: linear-gradient(#AB8EC0, #3C1E5D);
    padding-top: 3vh;
    h1 {
      color: white;
      font-weight: 600;
      letter-spacing: 1px;
      margin-bottom: 0.8em;
      font-size: 2rem;
    }

    .time-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      text-transform: uppercase;

      .time-card {
        border-top-left-radius: 14px;
        border-top-right-radius: 14px;
        background: rgb(255, 255, 255);
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(242, 241, 241, 1) 70%,
          rgba(180, 179, 179, 1) 100%
        );

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        min-width: 120px;
        width: 10vw;
        margin: 0 1%;
        margin-top: 5px;
        padding-top: 10px;

        p {
          margin-bottom: 6px;
          font-weight: 500;
        }

        h2 {
          font-weight: 700;
          font-size: 3.2rem;
        }
      }
    }
  }
  .btn {
    background: ${COLORS.primaryLight};
    border: 2px solid #AB8EC0;
    color: #FFFFFF;
    font-weight: 500;
    padding: 4px 24px;
    border-radius: 32px;
    display: block;
    margin: 12px;
  }
  .timeup {
    color: white;
    font-weight: 600;
    font-size: 24px;
  }
  #bg {
    background: url(${config.assets.entrance.landscape});
    background-size: cover;
    background-repeat: no-repeat;
    /* height: calc(100vh - 200px); */
    height: 100vh;
    img.portrait {
      display: none;
    }
    ${SIZES.mobile} {
      background: url(${BG});
      background-size: cover;
      background-repeat: no-repeat;
      padding-bottom: 55px;
      img.portrait {
        display: block;
        margin: 0 auto;
      }
    }
  }

`

export default function CountdownPage() {
  const calculateTimeLeft = () => {
    // year, actual month - 1, day, hour, minute, second, ignore
    // 13 Mar 2021 4:00pm(3:30pm)
    const difference = +new Date(2021, 2, 13, 15, 0, 0, 0) - +new Date()
    let timeLeft = {}

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      }
    }

    return timeLeft
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

  useEffect(() => {
    localStorage.clear()
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft())
    }, 1000)
  })

  const timerComponents = []

  Object.keys(timeLeft).forEach((interval) => {
    if (isNaN(timeLeft[interval])) {
      return
    }

    timerComponents.push(
      <div className="time-card">
        <p>{interval}</p>
        <h2>{timeLeft[interval]}</h2>
      </div>
    )
  })
  const refreshPage = () => {
    window.location.reload()
  }

  return (
    <Page>
      <div id="content">
        <div id="header">
          <h1>Managing Mild-Moderate-Severe Picky Eaters</h1>
          <div className="time-wrapper">
            {
              timerComponents.length ? 
              timerComponents 
              : 
              <span className="timeup">
                Time's up!
                <button className="btn" onClick={refreshPage}>
                  Enter Event
                </button>
              </span>
            }
          </div>
        </div>
        <div id="bg">
          <img className="portrait" src={banner} width="100%" alt="banner"/>
          {/* <img className="portrait mt-4" src={logo} width="80%" alt="logo"/> */}
        </div>
      </div>
    </Page>
  )
}
