import React from 'react'
import styled from 'styled-components'
import MobileNav from '../../components/MobileNav'

const MobileHelp = ({ currentUser }) => {
  const showWidget = () => {
    // const cpt = window.chaport
    // cpt.on('ready', function () {
    //   cpt.setVisitorData({
    //     name: currentUser.fullName,
    //     email: currentUser.email,
    //   })
    //   cpt.open()
    // })
    // cpt.on('collapsed', function () {
    //   cpt.close()
    // })
  }

  return (
    <div>
      <MobileNav />
      <Page>
        <button type="button" className="button" onClick={showWidget}>
          Open help desk chat
        </button>
      </Page>
    </div>
  )
}

const Page = styled.div`
  background: white;
  height: calc(100% - 60px);
  width: 100vw;
  overflow-y: scroll;
  border-radius: 0px 0px 25px 25px;
  position: absolute;

  .button {
    font-size: 14px;
    color: white;
    background: #e12929;
    border: none;
    border-radius: 30px;
    padding: 10px 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

export default MobileHelp
