import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import routes from '../../routes'
import slide1 from '../../images/gallerium/posterGallery/Slide1.JPG'
import slide2 from '../../images/gallerium/posterGallery/Slide2.JPG'
import slide3 from '../../images/gallerium/posterGallery/Slide3.JPG'
import slide4 from '../../images/gallerium/posterGallery/Slide4.JPG'
import slide5 from '../../images/gallerium/posterGallery/Slide5.JPG'
import slide6 from '../../images/gallerium/posterGallery/Slide6.JPG'

const MobilePosterGallery = ({ currentUser }) => {
  const [zoomIndex, setZoomIndex] = useState(-1)

  return (
    <Page>
      <div className="outer-wrapper">
        <div className="container">
          <div className="title-container">
            <p className="title">E Poster Gallery</p>
            <Link className="back-button" to={routes.lobby}>
              Back
            </Link>
          </div>

          {zoomIndex > -1 ? (
            <img
              alt=""
              src={
                [slide1,slide2,slide3,slide4,slide5,slide6][zoomIndex]
              }
              className="image-zoom"
              onClick={() => setZoomIndex(-1)}
            />
          ) : (
            <div className="list-container">
              {[slide1,slide2,slide3,slide4,slide5,slide6].map((item, index) => (
                  <div className="list-item" key={index}>
                    <img
                      alt=""
                      src={item}
                      className="list-image"
                      onClick={() => setZoomIndex(index)}
                    />
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
    </Page>
  )
}

const Page = styled.div`
  background: white;
  height: calc(100% - 60px);
  width: 100vw;
  overflow-y: scroll;
  border-radius: 0px 0px 25px 25px;
  position: absolute;

  .outer-wrapper {
    width: 100%;
  }

  .container {
    width: 90%;
    margin: auto;
  }

  .title-container {
    margin: 30px 0px 10px 0px;
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .title {
    width: 80%;
    font-size: 22px;
    font-weight: bold;
    font-style: italic;
    color: black;
    margin: 0;
  }

  .back-button {
    font-size: 12px;
    color: #312384;
    font-weight: bold;
    margin-top: 10px;
  }

  .list-container {
    width: 100%;
    column-count: 2;
  }

  .list-item {
    width: 100%;
  }

  .list-image {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }

  .image-zoom {
    width: 100%;
  }

  @media screen and (min-width: 540px) {
    .outer-wrapper {
      width: 540px;
      margin: auto;
    }
  }
`

export default MobilePosterGallery
