import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import Amplitude from '../utils/amplitudeHelper'
import PageWithBg from '../components/PageWithBg'
import LobbyOverlay from '../components/LobbyOverlay'
import LobbyEntry from '../components/LobbyEntry'
import { usePageActiveUserCount } from '../utils/activeUserCount'
import config from '../config'
import { toast } from 'react-toastify'

const PageWrapper = styled.div`
  
`

export default function LobbyPage({ currentUser }) {
  const [overlayTitle, setOverlayTitle] = useState('Select hall to enter:')
  const [overlay, setOverlay] = useState(null)

  const showEntryOverlay = (destinations) => {
    setOverlay(destinations)
  }

  // usePageActiveUserCount(currentUser)

  const hideOverlay = () => setOverlay(null)

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      hideOverlay()
    }
  }, [])

  const showHelpDesk = () => {
    toast("Event has ended.")
    // const cpt = window.chaport
    // cpt.on('ready', function () {
    //   cpt.setVisitorData({
    //     name: currentUser.fullName,
    //     email: currentUser.email,
    //   })
    //   cpt.open()
    // })
    // cpt.on('collapsed', function () {
    //   cpt.close()
    // })
    // Amplitude.clickHelpDesk()
  }

  useEffect(() => {
    document.title = `${config.eventName} | Lobby`
  }, [])

  useEffect(() => {
    Amplitude.visit("Lobby")
  }, [currentUser.id])

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false)

    return () => {
      document.removeEventListener('keydown', escFunction, false)
    }
  }, [escFunction])

  return (
    <PageWrapper>
      <PageWithBg bgImg={config.assets.lobby.landscape}>
        <LobbyOverlay
          overlayTitle={overlayTitle}
          overlay={overlay}
          hideOverlay={hideOverlay}
        />
        {config.lobbyEntries.map((entry) => {
          return (
            <LobbyEntry
              showHelpDesk={showHelpDesk}
              key={entry.id}
              setOverlayTitle={setOverlayTitle}
              showEntryOverlay={showEntryOverlay}
              {...entry}
            />
          )
        })}
      </PageWithBg>
    </PageWrapper>
  )
}
