import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Amplitude from '../utils/amplitudeHelper'
import { useParams, useHistory } from 'react-router-dom'
import { LazyLoadImage, LazyLoadComponent } from 'react-lazy-load-image-component'
import { useDispatch, useSelector } from 'react-redux'
import { loadTawk } from '../utils/tawkToHelper'
import { getBooth, getBooths } from '../ducks/booth'
import PageWithBg from '../components/PageWithBg'
import LoadIcon from '../components/LoadIcon'
import DownloadModal from '../components/GlobalModals/DownloadModal'
import IframeModal from '../components/GlobalModals/IframeModal'
import PosterModal from '../components/GlobalModals/PosterModal'
import InfoModal from '../components/GlobalModals/InfoModal'
import VideoModal from '../components/GlobalModals/VideoModal'
import TawkModal from '../components/GlobalModals/TawkModal'
import undoIcon from '../images/navigation/undo.png'
import leftIcon from '../images/booth/Arrow-left.png'
import rightIcon from '../images/booth/Arrow-right.png'
import boothBg from '../images/backdrops/boothBg.jpg'
import booth1 from '../images/booth/booth1_btn.png'
import booth2 from '../images/booth/booth2_btn.png'
import booth3 from '../images/booth/booth3_btn.png'
import BoothNamecardModal from '../components/GlobalModals/BoothNamecardModal'
import routes from '../routes'
import BoothImages from '../constants/exhibitionBooths'

const Page = styled.div`
  overflow: hidden;
  #button-container {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    bottom: 4vh;
    left: 4%;
    right: 4%;
    .hot-buttons {
      width: 125px;
      background: linear-gradient(#e20613, #652781);
      border-radius: 40px;
      border: 1px solid #ffad2c;
      padding: 8px 16px;
      color: #bbbdbf;
      margin: 0 8px;
      &:hover {
        box-shadow: 0px 3px 12px #ffad2c;
      }
      small {
        margin: 0;
        display: block;
        font-weight: 500;
        font-size: 70%;
      }
    }
  }
  .hot-actions {
    background: transparent;
    border: none;
    /* border: 1px solid red; */
    padding: 8px 16px;
    color: #bbbdbf;
    margin: 0 8px;
    &:hover {
      cursor: pointer;
    }
  }
  .booth-container {
    position: fixed;
    /* width: 85vw; */
    height: 85%;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -68%);
    .hot-actions {
      padding: 0;
      img,
      svg {
        transition: 500ms;
        width: 28px;
        height: 28px;
      }
      small {
        transition: 500ms;
        font-size: 1px;
        opacity: 0;
        color: white;
        display: none;
      }
      &:hover {
        box-shadow: 0px 3px 12px #ffad2c;
        small {
          font-size: 8px;
          opacity: 1;
          display: block;
        }
        small.extra-small {
          font-size: 6px;
        }
        img,
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
  #tawk-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 40vw;
    height: 40vh;
    z-index: 9999;
  }
`
const HotButton = styled.button`
  position: absolute;
  width: 75px;
  height: 75px;
  ${(props) => props.css}
`

const EMBED_DIV_ID = 'TAWK_EMBED'

export default function BoothPage({ currentUser }) {
  const [showBtn, setShowBtn] = useState(false)
  const [isOpen, setOpen] = useState(false)
  const [posterOpen, setPosterOpen] = useState(false)
  const [secondPosterOpen, setSecondPosterOpen] = useState(false)
  const [downloadOpen, setDownloadOpen] = useState(false)
  const [infoOpen, setInfoOpen] = useState(false)
  const [videoOpen, setVideoOpen] = useState(false)
  const [tawkOpen, setTawkOpen] = useState(false)
  const [nameCardModal, setNameCardModal] = useState(false)
  const [playVideo, setPlayVideo] = useState({})

  const dispatch = useDispatch()
  const { viewBooth, booths } = useSelector((state) => state.booth)
  const { boothId } = useParams()
  const history = useHistory()

  useEffect(() => {
    dispatch(getBooth(boothId))
  }, [boothId, dispatch])

  useEffect(() => {
    if (viewBooth && viewBooth.id == boothId) {
      dispatch(getBooths(viewBooth.hall.id))
      Amplitude.visitBooth(viewBooth.title)
    }
  }, [boothId, currentUser.id, viewBooth])

  const handleModal = (video) => {
    setPlayVideo({
      title: 'Video',
      link: video + '?autoplay=1',
    })
    setOpen(true)
    setVideoOpen(false)
  }

  const handleDownload = () => {
    Amplitude.clickHotButtons(viewBooth.title, 'pdf')
    setDownloadOpen(!downloadOpen)
  }

  const handleVideoModal = () => {
    Amplitude.clickHotButtons(viewBooth.title, 'video')
    if (viewBooth.video_urls.length > 1) {
      setVideoOpen(!videoOpen)
    } else {
      setPlayVideo({
        title: 'Video',
        link: viewBooth.video_urls[0],
      })
      setOpen(true)
      setVideoOpen(false)
    }
  }
  const handleDropNameCard = () => {
    Amplitude.clickHotButtons(viewBooth.title, 'namecard')
    setNameCardModal(!nameCardModal)
  }
  const handlePoster = () => {
    Amplitude.clickHotButtons(viewBooth.title, 'poster')
    setPosterOpen(!posterOpen)
  }
  const handleSecondPoster = () => {
    Amplitude.clickHotButtons(viewBooth.title, 'poster')
    setSecondPosterOpen(!secondPosterOpen)
  }

  const openTawk = () => {
    if (viewBooth) {
      Amplitude.clickHotButtons(viewBooth.title, 'chat')
      const { chat_link } = viewBooth
      loadTawk(currentUser, chat_link, EMBED_DIV_ID)
      setTawkOpen(!tawkOpen)
    }
  }

  const getBtnImage = () => {
    if (viewBooth.id === 1) return booth1
    if (viewBooth.id === 2) return booth2
    if (viewBooth.id === 3) return booth3
  }
  const navigatePrev = () => {
    setShowBtn(false)
    const navigateTo = booths.filter(booth=>booth.order===viewBooth.order-1)[0].id
    history.push(`/booths/${navigateTo}`)
  }
  const navigateNext = () => {
    setShowBtn(false)
    const navigateTo = booths.filter(booth=>booth.order===viewBooth.order+1)[0].id
    history.push(`/booths/${navigateTo}`)
  }

  const openGame = () => {
    Amplitude.clickHotButtons(viewBooth.title, 'game')
    window.open('https://kahoot.it/challenge/05066546', '_blank')
  }

  return (
    <Page>
      <PageWithBg bgImg={boothBg}>
        {!showBtn && <LoadIcon />}
        {viewBooth && (
          <LazyLoadComponent>
            <div className="booth-container">
              <LazyLoadImage
                effect="black-and-white"
                afterLoad={() => setShowBtn(true)}
                className="booth-img"
                src={viewBooth.background_image.url}
                height="100%"
                alt="booth image"
              />
              
            </div>
            <div id="button-container" style={{flexDirection: viewBooth.id % 2 ? 'row' : 'row-reverse'} }>
              <div style={{width: viewBooth.id > 1 ? '35vw' : '30vw'}}>
              <img src={getBtnImage()} width="100%" alt="hot-buttons" />
              {showBtn && (
                <>
                  {
                    viewBooth.btnPos.vid && 
                    <HotButton
                      css={viewBooth.btnPos.vid}
                      className="hot-actions"
                      onClick={handleVideoModal}
                    />
                  }
                  {
                    viewBooth.btnPos.image &&
                    <HotButton
                      css={viewBooth.btnPos.image}
                      className="hot-actions"
                      onClick={handlePoster}
                    />
                  }
                  {
                    viewBooth.btnPos.pdf && 
                    <HotButton
                      css={viewBooth.btnPos.pdf}
                      className="hot-actions"
                      onClick={handleDownload}
                    />
                  }
                  {
                    viewBooth.btnPos.image2 &&
                    <HotButton
                      css={viewBooth.btnPos.image2}
                      className="hot-actions"
                      onClick={handleSecondPoster}
                    />
                  }
                  {
                    viewBooth.btnPos.chat &&
                    <HotButton
                      css={viewBooth.btnPos.chat}
                      className="hot-actions"
                      onClick={openTawk}
                    />
                  }
                  {
                    viewBooth.email && (
                      <HotButton
                        css={viewBooth.btnPos.namecard}
                        className="hot-actions"
                        onClick={handleDropNameCard}
                      />
                    )
                  }
                  {
                    viewBooth.btnPos.game && (
                      <HotButton
                        css={viewBooth.btnPos.game}
                        className="hot-actions"
                        onClick={openGame}
                      />
                    )
                  }
                </>
              )}
              </div>
              <div>
                <button
                  className="hot-buttons"
                  onClick={() =>
                    history.push(routes.exhibition1)
                  }
                >
                  <img src={undoIcon} width="30" alt="Nav" />
                  <small>Return to Hall</small>
                </button>
                {viewBooth.order !== 0 && (
                  <button className="hot-buttons" onClick={navigatePrev}>
                    <img src={leftIcon} width="15" alt="Nav" />
                    <small>Previous Booth</small>
                  </button>
                )}
                {viewBooth.order !== booths.length-1 && (
                  <button className="hot-buttons" onClick={navigateNext}>
                    <img src={rightIcon} width="15" alt="Nav" />
                    <small>Next Booth</small>
                  </button>
                )}
              </div>
            </div>
            {
              videoOpen &&
              <VideoModal
                videos={viewBooth.video_urls}
                setVideoOpen={setVideoOpen}
                videoOpen={videoOpen}
                handleModal={handleModal}
              />
            }
            {
              isOpen &&
              <IframeModal
                iframeOpen={isOpen}
                setIframeOpen={setOpen}
                iframe={playVideo}
              />
            }
            {
              downloadOpen &&
              <DownloadModal
                files={viewBooth.documents}
                setDownloadOpen={setDownloadOpen}
                downloadOpen={downloadOpen}
              />
            }
            {
              posterOpen &&
              <PosterModal
                images={viewBooth.images}
                size='lg'
                posterOpen={posterOpen}
                setPosterOpen={setPosterOpen}
              />
            }
            {
              secondPosterOpen &&
              <PosterModal
                images={BoothImages}
                size='md'
                posterOpen={secondPosterOpen}
                setPosterOpen={setSecondPosterOpen}
              />
            }
            {
              infoOpen &&
              <InfoModal
                title={viewBooth.title}
                description={viewBooth.description}
                content={viewBooth.content}
                agents={viewBooth.booth_agents}
                infoOpen={infoOpen}
                setInfoOpen={setInfoOpen}
              />
            }
            {
              tawkOpen &&
              <TawkModal
                embedId={EMBED_DIV_ID}
                tawkOpen={tawkOpen}
                setTawkOpen={setTawkOpen}
              />
            }
            {
              nameCardModal && 
              <BoothNamecardModal 
                currentUser={currentUser} 
                hideModal={()=>setNameCardModal(false)} 
                modal={nameCardModal} 
                booth={viewBooth} 
              />
            }
          </LazyLoadComponent>
        )}
      </PageWithBg>
    </Page>
  )
}
