import React from 'react';
import { Switch, Route } from 'react-router-dom';

import MobilePosterGallery from './MobilePosterGallery.js';
// import MobileGalleriumGallery from './MobileGalleriumGallery.js';
// import MobileGalleriumHome from './MobileGalleriumHome.js';
// import MobileGalleriumInfographics from './MobileGalleriumInfographics.js';
// import MobileGalleriumInfographicsItem from './MobileGalleriumInfographicsItem.js';
// import MobileGalleriumMagazines from './MobileGalleriumMagazines.js';
// import MobileGalleriumVideos from './MobileGalleriumVideos.js';
// import MobileGalleriumVideosItem from './MobileGalleriumVideosItem.js';

const MobileGallerium = ({ currentUser }) => {
  return (
    <div>
      <Switch>

        <Route exact path='/gallerium'>
          <MobilePosterGallery currentUser={currentUser} />
        </Route>
        {/* <Route exact path='/gallerium'>
          <MobileGalleriumInfographics currentUser={currentUser} />
        </Route>

        <Route exact path='/gallerium/infographics'>
          <MobileGalleriumInfographics currentUser={currentUser} />
        </Route>

        <Route exact path='/gallerium/infographics/:name'>
          <MobileGalleriumInfographicsItem currentUser={currentUser} />
        </Route>

        <Route exact path='/gallerium/videos-podcast'>
          <MobileGalleriumVideos currentUser={currentUser} />
        </Route>

        <Route exact path='/gallerium/videos-podcast/:name'>
          <MobileGalleriumVideosItem currentUser={currentUser} />
        </Route>

        <Route exact path='/gallerium/magazines'>
          <MobileGalleriumMagazines currentUser={currentUser} />
        </Route>

        <Route exact path='/gallerium/virtual-gallery'>
          <MobileGalleriumGallery currentUser={currentUser} />
        </Route> */}
        
      </Switch>
    </div>
  );
}


export default MobileGallerium;