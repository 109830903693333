import React, { useState } from 'react'
import styled from 'styled-components'
import Avatar from 'react-avatar'
import { COLORS } from '../../styles/theme'
import DropNamecardModal from './DropNamecardModal'
import bizCard from '../../images/icons/bizCard.png'

const Page = styled.div`
  height: 100%;
  padding: 32px 24px;
  /* backdrop-filter: blur(20px); */
  background-color: rgba(255,255,255,0.75);
  font-size: 0.8em;

  h5 {
    color: ${COLORS.primary};
    font-weight: 600;
    text-align: left;
  }
  .profile-image {
    position: relative;
    width: 125px;
    height: 125px;
    border-radius: 50%;
    margin: 10px auto;

    .avatar {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
    .sb-avatar {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 10%;
    }
    .icon {
      position: absolute;
      left: 60%;
      bottom: 10%;
    }
  }
  .contact {
    color: ${COLORS.lightGray1};
  }
  .drop-namecard {
    display: flex;
    justify-content: center;
    a,
    button {
      background-image: linear-gradient(#492777, #270D48);
      color: #BBBDBF;
      width: 100%;
      max-width: 150px;
      font-size: 1em;
      border-radius: 32px;
      padding: 0.5em 0.8em;
      box-shadow: none;
      outline: none;
      border: solid 1px ${COLORS.primary};
      margin-bottom: 1em;
      font-size: 1.1em;
      font-weight: 500;
      transition: 100ms;
      display: flex;
      align-items: center;
      &:hover {
        color: white;
      }
  }
  }
`

export default function ContactCard({ user, isSelf, currentUser }) {
  const [modal, setModal] = useState(false)

  const openModal = () => setModal(true)
  const hideModal = () => setModal(false)

  return (
    <Page>
      <DropNamecardModal currentUser={currentUser} hideModal={hideModal} modal={modal} user={user} />
      <h5 className="text-center mb-4 heading">Contact Card</h5>
      <div className="profile-image">
        {
          user.avatar ?
          <img src={user.avatar.url} width="125px" className="avatar" alt="profile-image" />
          :
          <Avatar round name={user.fullName} size="125px" />
        }
        {/* <svg className="icon" height="30" width="30">
          <g transform="translate(0 -1028.4)">
            <path d="m22 12c0 5.523-4.477 10-10 10-5.5228 0-10-4.477-10-10 0-5.5228 4.4772-10 10-10 5.523 0 10 4.4772 10 10z" transform="translate(0 1029.4)" fill="#fff"/>
            <path d="m22 12c0 5.523-4.477 10-10 10-5.5228 0-10-4.477-10-10 0-5.5228 4.4772-10 10-10 5.523 0 10 4.4772 10 10z" transform="translate(0 1028.4)" fill="#C3A97A"/>
            <path d="m6.0001 1042.4h4.9999v5h2v-5h5v-2h-5v-5h-2v5h-4.9999v2z" fill="#fff"/>
            <path d="m6 1041.4h5v5h2v-5h5v-2h-5v-5h-2v5h-5v2z" fill="#fff"/>
          </g>
        </svg> */}
      </div>
      <div className="details">
        <h5 className="text-center">{user.fullName}</h5>
        <p>{user.companyName}</p>
        <p className="contact">{user.designation}</p>
        {/* <p className="contact">
          Mobile: {user.phone}
          <br />
          Email: {user.email}
        </p> */}
        {isSelf ? (
          <p className="text-dark">
            <em>This is your information</em>
          </p>
        ) : (
          <div className="drop-namecard">
            <button onClick={openModal}>
              <img src={bizCard} width="30px" alt="business card"/>
              <span>Drop Your Name Card</span>
            </button>
          </div>
        )}
        {/* <p className="font-weight-bold font-italic">ShoutOut Message</p> */}
        {/* <p><small className="text-secondary font-italic">Marketer by day, gamer at night. </small></p> */}
        {/* <Button variant="default" className="my-3" >Edit Profile</Button> */}
      </div>
    </Page>
  )
}
