import React, { useMemo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Switch, Route } from 'react-router-dom'
import Media from 'react-media'

import config from './config'
import routes from './routes'
import { SIZES } from './styles/theme'
import ModalContainer from './components/GlobalModals/ModalContainer'
import EntrancePages from './containers/EntrancePages'
import PrivateRoute from './containers/PrivateRoute'

import CountdownPage from './pages/CountdownPage'
import ActiveCountPage from './pages/ActiveCountPage'
import SetPasswordFormPage from './pages/registration/SetPasswordFormPage'
import PreRegisterPage from './pages/registration/PreRegisterPage'
import RegisterFormPage from './pages/registration/RegisterFormPage'
import PostRegisterPage from './pages/registration/PostRegisterPage'

import LobbyPage from './webOnlyPages/LobbyPage'
import AuditoriumPage from './webOnlyPages/AuditoriumPage'
import NetworkingLoungePage from './webOnlyPages/NetworkingLoungePage'
import ExhibitionPage from './webOnlyPages/ExhibitionPage'
import BoothPage from './webOnlyPages/BoothPage'
import PosterGalleryPage from './webOnlyPages/PosterGalleryPage'
import SubGalleriumPage from './webOnlyPages/SubGalleriumPage'
import SubSectionPage from './webOnlyPages/SubSectionPage'

import MobileEditProfile from './mobileOnlyPages/MobileEditProfile/index.js'
import MobileEntrance from './mobileOnlyPages/MobileEntrance/index.js'
import MobileExpoBooth from './mobileOnlyPages/MobileExpo/MobileExpoBooth.js'
import MobileExpoDirectory from './mobileOnlyPages/MobileExpo/MobileExpoDirectory.js'
import MobileGallerium from './mobileOnlyPages/MobileGallerium/index.js'
import MobileHelp from './mobileOnlyPages/MobileHelp/index.js'
import MobileAuditorium from './mobileOnlyPages/MobileKFair20/index.js'
import MobileLobby from './mobileOnlyPages/MobileLobby/index.js'
import MobileMenu from './mobileOnlyPages/MobileMenu/index.js'
import MobileNetworking from './mobileOnlyPages/MobileNetworking/index.js'
import MobileNotifications from './mobileOnlyPages/MobileNotifications/index.js'

function App() {
  const { currentUser, isAuthenticated } = useSelector((state) => state.auth)
  const today = new Date()
  useEffect(() => {
    document.title = config.eventName
  }, [])

  // useEffect(() => {
  //   window.chaport.q('hideLauncher')
  // }, [])

  return useMemo(() => {
    return (
      <>
        <ModalContainer currentUser={currentUser} />

        {/* Registration */}
        <Switch>
          <Route exact path="/realtime" component={ActiveCountPage} />
          <Route exact path={routes.preRegister} component={PreRegisterPage} />
          <Route exact path={routes.register} component={RegisterFormPage} />
          <Route exact path={routes.postRegister} component={PostRegisterPage} />
        </Switch>

        <Media query={`(max-width: ${SIZES.mobileBreakpoint})`}>
          {(matches) =>
            matches ? (
              <Switch>
                {/* Entrance */}
                <Route exact path={routes.forgotPassword}>
                  <MobileEntrance />
                </Route>
                <Route exact path={routes.resetPassword}>
                  <MobileEntrance />
                </Route>
                <Route exact path={routes.login}>
                  <MobileEntrance />
                </Route>

                <Route exact path={routes.root}>
                  <MobileEntrance />
                  {/* <CountdownPage /> */}
                </Route>

                {/* Lobby */}
                <PrivateRoute
                  exact
                  mobileNav
                  currentUser={currentUser}
                  authed={isAuthenticated}
                  path={routes.lobby}
                  component={MobileLobby}
                />

                {/* Help */}
                <PrivateRoute
                  exact
                  currentUser={currentUser}
                  authed={isAuthenticated}
                  path={'/help'}
                  component={MobileHelp}
                />

                {/* KFair20 */}
                <PrivateRoute
                  exact
                  mobileNav
                  currentUser={currentUser}
                  authed={isAuthenticated}
                  path={routes.auditorium1}
                  component={(props) => (
                    <MobileAuditorium
                      {...props}
                      hallNumber={1}
                      liveUrl="https://player.vimeo.com/video/534274027?autoplay=1"
                    />
                  )}
                />

                {/* Networking Hall */}
                <PrivateRoute
                  exact
                  mobileNav
                  currentUser={currentUser}
                  authed={isAuthenticated}
                  path={routes.networking}
                  component={MobileNetworking}
                />

                {/* Expo Hall A */}
                <PrivateRoute
                  exact
                  mobileNav
                  currentUser={currentUser}
                  authed={isAuthenticated}
                  path={routes.exhibition1}
                  component={(props) => (
                    <MobileExpoDirectory
                      {...props}
                      hallNumber={1}
                    />
                  )}
                />

                {/* Expo Hall b */}
                <PrivateRoute
                  exact
                  mobileNav
                  currentUser={currentUser}
                  authed={isAuthenticated}
                  path={routes.exhibition2}
                  component={(props) => (
                    <MobileExpoDirectory
                      {...props}
                      hallNumber={2}
                    />
                  )}
                />

                {/* Gallerium */}
                <PrivateRoute
                  mobileNav
                  currentUser={currentUser}
                  authed={isAuthenticated}
                  path={routes.gallerium}
                  component={MobileGallerium}
                />

                {/* Dynamic booth */}
                <PrivateRoute
                  exact
                  mobileNav
                  currentUser={currentUser}
                  authed={isAuthenticated}
                  path="/booths/:boothId"
                  component={MobileExpoBooth}
                />

                {/* Menu */}
                <PrivateRoute
                  exact
                  mobileNav
                  currentUser={currentUser}
                  authed={isAuthenticated}
                  path="/menu"
                  component={MobileMenu}
                />

                <PrivateRoute
                  exact
                  mobileNav
                  currentUser={currentUser}
                  authed={isAuthenticated}
                  path="/edit-profile"
                  component={MobileEditProfile}
                />

                {/* Notifications */}
                <PrivateRoute
                  exact
                  mobileNav
                  currentUser={currentUser}
                  authed={isAuthenticated}
                  path={routes.notifications}
                  component={MobileNotifications}
                />

              </Switch>
            ) : (
              <Switch>
                <Route
                  exact
                  path={routes.resetPassword}
                  component={SetPasswordFormPage}
                />

                {/* Entrance / Login Pages */}
                <Route exact path={routes.login} component={EntrancePages} />
                <Route exact path={routes.root} component={EntrancePages} />
                {/* <Route exact path={routes.root} component={CountdownPage} /> */}

                <PrivateRoute
                  exact
                  fixedNav
                  currentUser={currentUser}
                  authed={isAuthenticated}
                  path={routes.lobby}
                  component={LobbyPage}
                />

                {/* Networking Hall */}
                <PrivateRoute
                  exact
                  fixedNav
                  currentUser={currentUser}
                  authed={isAuthenticated}
                  path={routes.networking}
                  component={NetworkingLoungePage}
                />

                {/* Auditorium (Vimeo) Hall */}
                <PrivateRoute
                  exact
                  fixedNav
                  currentUser={currentUser}
                  authed={isAuthenticated}
                  path={routes.auditorium1}
                  component={(props) => (
                    <AuditoriumPage
                      {...props}
                      hallNumber={1}
                      hallBg={config.assets.auditorium1.landscape}
                      liveUrl="https://player.vimeo.com/video/534274027?autoplay=1"
                    />
                  )}
                />

                {/* Exhibition Hall */}
                <PrivateRoute
                  exact
                  fixedNav
                  currentUser={currentUser}
                  authed={isAuthenticated}
                  path={routes.exhibition1}
                  component={(props) => (
                    <ExhibitionPage
                      {...props}
                      hallNumber={1}
                      hallBg={config.assets.exhibition1}
                    />
                  )}
                />
                <PrivateRoute
                  exact
                  fixedNav
                  currentUser={currentUser}
                  authed={isAuthenticated}
                  path={routes.exhibition2}
                  component={(props) => (
                    <ExhibitionPage
                      {...props}
                      hallNumber={2}
                      hallBg={config.assets.exhibition2}
                    />
                  )}
                />
                {/* Sub-section of gallerium category */}
                <PrivateRoute
                  fixedNav
                  currentUser={currentUser}
                  authed={isAuthenticated}
                  path={`/gallerium/:category/:name`}
                  component={SubSectionPage}
                />

                {/* Sub-Gallerium */}
                <PrivateRoute
                  fixedNav
                  currentUser={currentUser}
                  authed={isAuthenticated}
                  path={`/gallerium/:category`}
                  component={SubGalleriumPage}
                />

                {/* Gallerium */}
                <PrivateRoute
                  exact
                  fixedNav
                  currentUser={currentUser}
                  authed={isAuthenticated}
                  path={routes.gallerium}
                  component={PosterGalleryPage}
                />

                {/* dynamic Booth */}
                <PrivateRoute
                  exact
                  fixedNav
                  currentUser={currentUser}
                  authed={isAuthenticated}
                  path="/booths/:boothId"
                  component={BoothPage}
                />
              </Switch>
            )
          }
        </Media>
      </>
    )
  }, [currentUser, isAuthenticated])
}

export default App
