import React from 'react'
import styled from 'styled-components'
import { Modal, Carousel } from 'react-bootstrap'
import { COLORS } from '../../styles/theme'

const StyledModal = styled(Modal)`
  .modal-content {
    border: 2px solid ${COLORS.primaryLight};
    border-radius: 28px;
    box-shadow: 0px 4px 12px ${COLORS.lightGray1} ;
  }
`
const StyledCarousel = styled(Carousel)`
  .carousel-control-next, 
  .carousel-control-prev {
    opacity: 1;
  }
  .carousel-control-prev {
    left: -8%;
  } 
  .carousel-control-next {
    right: -8%;
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    background-image: none;
  }

  span.carousel-control-next-icon:after {
    content: '>';
    font-size: 35px;
    font-weight: 700;
    color: ${COLORS.primaryTrans};
  }

  span.carousel-control-prev-icon:after {
    content: '<';
    font-size: 35px;
    font-weight: 700;
    color: ${COLORS.primaryTrans};
  }
`
export default function PosterModal({ images, posterOpen, setPosterOpen, size }) {
  return (
    <StyledModal
      show={posterOpen}
      onHide={()=>setPosterOpen(false)}
      aria-labelledby="images-modal"
      centered
      size={size}
    > 
      <Modal.Body>
        {
          images.length > 0 ? (
            images.length > 1 ?
            <StyledCarousel>
              {
                images.map((poster,index)=>(
                  <Carousel.Item key={`poster-${index}`} interval={4000}>
                    <img
                      className="d-block w-100"
                      src={poster.url}
                      alt={`poster-${index}`}
                    />
                  </Carousel.Item>
                ))
              }
            </StyledCarousel>
            :
            <img
              className="d-block w-100"
              src={images[0].url}
              alt="poster"
            />
          ) :
          <h6 className="text-secondary text-center m-0">No poster uploaded for this booth.</h6>
        }
      </Modal.Body>
    </StyledModal>
  )
}
