import React from 'react'
import styled from 'styled-components'

const SearchInput = styled.div`
  margin: 1.5em 0;
  position: relative;

  #search-input {
    width: 100%;
    border: solid 1px #C5A0FC;
    border-radius: 17px;
    padding: 0.5em;
    padding-left: 2.5em;
  }

  svg {
    position: absolute;
    left: 1em;
    top: 50%;
    transform: translateY(-50%);
  }
`

const UserSearch = React.memo(({ search, setSearch }) => {
  const handleInput = (e) => {
    setSearch(e.target.value)
  }
  return (
    <SearchInput>
      <input
        type="text"
        id="search-input"
        value={search}
        onChange={handleInput}
        placeholder="Search a name or company..."
      />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#4f4f4f"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <circle cx="11" cy="11" r="8"></circle>
        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
      </svg>
    </SearchInput>
  )
})

export default UserSearch
