import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Axios from 'axios'
import config from '../config'
import AuditoriumAgendaItem from './AuditoriumAgendaItem'
import { useList } from 'react-firebase-hooks/database'
import LoadIcon from './LoadIcon2'

const AgendaWrapper = styled.div`
  padding: 32px 0;
  .buttons-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
`

export default function AuditoriumAgenda({ currentUser }) {
  const [agendaList, setAgendaList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    Axios({
      url: `${config.apiUrl}/api/v1/hall_sessions?hall_id=1`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('jwt_token')}`,
      },
    }).then((result) => {
      setIsLoading(false)
      const sortedSession = result.data.sessions.sort((a, b)=>{
        return new Date(a.start_at) - new Date(b.start_at); 
      })
      setAgendaList(sortedSession);
    })
  }, [])

  if (isLoading) {
    return <LoadIcon />
  }
  return (
    <AgendaWrapper>
      <section className="agenda-container">
        <div>
          {agendaList.map((agenda) => (
            <AuditoriumAgendaItem agenda={agenda} agendaList={agendaList} setAgendaList={setAgendaList} key={agenda.id}/>
          ))}
        </div>
      </section>
    </AgendaWrapper>
  )
}
