import { createGlobalStyle } from 'styled-components'
import { COLORS, FONTS, SIZES } from './theme'

const GlobalStyle = createGlobalStyle`
  /* body > #zmmtg-root {
    display: none;
  } */

  html {
    color: ${COLORS.text};
  }

  body {
    background: black;
    font-family: ${FONTS.primary};
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: ${FONTS.secondary};
  }

  * {
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
  }

  *:focus {
      outline: none !important;
  }

  .text-red {
    color: ${COLORS.primary} !important;
  }

  .flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #live-pw {
    line-height: 1.2;
    text-align: center;
    font-size: .7rem;
    font-weight: bold;
    border-radius: 2px;
    padding: 3px;
    .pw {
      letter-spacing: 1px;
      margin: 0;
      font-weight: bold;
      color: ${COLORS.primary};
      font-size: 1.3em;
    }

    ${SIZES.mobile} {
      font-size: .5em;
      .pw {
        font-size: 1.2em;
      }
    }
  }

  .Toastify__toast {
    color: ${COLORS.text};
    /* border-radius: 8px; */
    font-family: ${FONTS.primary};
    padding: 8px 14px;
    background: rgba(255, 255, 255, .8);
  }

  .Toastify__toast-container {
  }

  @keyframes heartbeat {
    0% {
      transform: scale(1);
    }
    15% {
      transform: scale(0.8);
    }
    30% {
      transform: scale(1.08);
    }
    60% {
      transform: scale(0.93);
    }
    90% {
      transform: scale(1);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-8%);
    }
    60% {
      transform: translateY(-4%);
    }
  }

  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }
`

export default GlobalStyle
