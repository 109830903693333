import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useParams, useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Carousel } from 'react-bootstrap'
import Amplitude from '../../utils/amplitudeHelper'
import routes from '../../routes'
import { getBooth, getBooths } from '../../ducks/booth'
import chevronLeft from '../../images/mobile/chevron-left.png'
import chevronRight from '../../images/mobile/chevron-right.png'
import play from '../../images/mobile/play.png'
import pdf from '../../images/mobile/pdf.png'
import image from '../../images/mobile/image.png'
import message from '../../images/mobile/message.png'
// import info from '../../images/mobile/info.png'
// import whatsapp from '../../images/mobile/whatsapp.png'
import boothBg from '../../images/backdrops/boothBg.jpg'
import LoadIcon from '../../components/LoadIcon.js'
import LoadIcon2 from '../../components/LoadIcon2.js'
import { loadTawk } from '../../utils/tawkToHelper'
import BoothNamecardModal from '../../components/GlobalModals/BoothNamecardModal'
import BoothImages from '../../constants/exhibitionBooths'

const MobileExpoBooth = ({ currentUser }) => {
  const history = useHistory()
  const { boothId } = useParams()
  const dispatch = useDispatch()
  const { viewBooth, booths } = useSelector((state) => state.booth)

  const [videoLoading, setVideoLoading] = useState(true)
  const [videoModal, setVideoModal] = useState(false)
  const [pdfModal, setPdfModal] = useState(false)
  const [imageModal, setImageModal] = useState(false)
  const [infoModal, setInfoModal] = useState(false)
  const [iframeModal, setIframeModal] = useState(false)
  const [namecardModal, setNamecardModal] = useState(false)
  const [videoLink, setVideoLink] = useState(null)
  const [showTawk, setShowTawk] = useState(false)

  useEffect(() => {
    if (viewBooth && viewBooth.id == boothId) {
      dispatch(getBooths(viewBooth.hall.id))
      Amplitude.visitBooth(viewBooth.title)
    }
  }, [boothId, currentUser.id, viewBooth])

  useEffect(() => {
    dispatch(getBooth(boothId))
  }, [boothId, dispatch])

  const goNext = () => {
    const navigateTo = booths.filter(booth=>booth.order===viewBooth.order+1)[0].id
    const nextLink = `/booths/${navigateTo}`
    history.push(nextLink)
  }

  const goPrevious = () => {
    const navigateTo = booths.filter(booth=>booth.order===viewBooth.order-1)[0].id
    const prevLink = `/booths/${navigateTo}`
    history.push(prevLink)
  }

  const toggleVideoModal = () => setVideoModal(!videoModal)
  const togglePdfModal = () => setPdfModal(!pdfModal)

  const toggleImageModal = () => {
    Amplitude.clickHotButtons(viewBooth.title, 'poster')
    setImageModal(!imageModal)
  }

  const toggleInfoModal = () => {
    Amplitude.clickHotButtons(viewBooth.title, 'info')
    setInfoModal(!infoModal)
  }
  const toggleIframeModal = () => {
    setVideoLink(null)
    setVideoLoading(true)
    setIframeModal(!iframeModal)
  }

  const onPlayVideo = (link) => {
    setVideoLink(link)
    setVideoModal(false)
    setIframeModal(true)
  }

  const handleTawk = () => {
    Amplitude.clickHotButtons(viewBooth.title, 'chat')
    loadTawk(
      currentUser,
      // 'https://embed.tawk.to/5fcf0f5a920fc91564ce7be4/1ep30671j',
      viewBooth.chat_link,
      'testtesttest'
    )
    setShowTawk(!showTawk)
  }

  const handleVideoClick = () => {
    Amplitude.clickHotButtons(viewBooth.title, 'video')
    if (viewBooth.video_urls.length !== 1) {
      setVideoModal(true)
    } else {
      setVideoLink(viewBooth.video_urls[0])
      setIframeModal(true)
    }
  }

  const handleNamecard = () => {
    Amplitude.clickHotButtons(viewBooth.title, 'namecard')
    setNamecardModal(!namecardModal)
  }

  const handlePdfClick = () => {
    Amplitude.clickHotButtons(viewBooth.title, 'pdf')
    setPdfModal(true)
  }

  const openGame = () => {
    Amplitude.clickHotButtons(viewBooth.title, 'game')
    window.open('https://kahoot.it/challenge/05066546', '_blank')
  }

  if (!viewBooth) {
    return (
      <Page>
        <LoadIcon2 />
      </Page>
    )
  }

  return (
    <Page>
      <div className="outer-wrapper">
        {iframeModal && (
          <div className="modal-container">
            <p className="modal-container-close" onClick={toggleIframeModal}>
              Close
            </p>
            <div className="modal-container-content">
              <div className="iframe-container">
                {videoLoading && <LoadIcon />}
                {videoLink && (
                  <iframe
                    title={videoLink}
                    src={videoLink}
                    allow="autoplay; fullscreen; encrypted-media"
                    allowFullScreen
                    allowtransparency="true"
                    frameBorder="0"
                    onLoad={() => {
                      setVideoLoading(false)
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        )}

        {videoModal && (
          <div className="modal-container">
            <p className="modal-container-close" onClick={toggleVideoModal}>
              Close
            </p>
            <div className="modal-container-content">
              <p className="modal-content-title">Select video to play</p>
              {viewBooth.video_urls.map((item, index) => (
                <div className="modal-content-row" key={index}>
                  <p className="modal-content-label">Video {index + 1}</p>
                  <button
                    onClick={() => onPlayVideo(item)}
                    className="modal-content-button"
                  >
                    Play
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}

        {pdfModal && (
          <div className="modal-container">
            <p className="modal-container-close" onClick={togglePdfModal}>
              Close
            </p>
            <div className="modal-container-content">
              <p className="modal-content-title">Select file to download</p>
              {viewBooth.documents.map((item, index) => (
                <div className="modal-content-row" key={index}>
                  <p className="modal-content-label">{item.filename}</p>
                  <a
                    href={item.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="modal-content-button"
                  >
                    Download
                  </a>
                </div>
              ))}
            </div>
          </div>
        )}

        {imageModal && (
          <div className="modal-container">
            <p className="modal-container-close" onClick={()=>setImageModal(false)}>
              Close
            </p>
            <div className="modal-container-content">
              <Carousel>
                {(viewBooth.id === 3 ? BoothImages.concat(viewBooth.images):viewBooth.images).map((item, index) => (
                  <Carousel.Item key={index}>
                    <img src={item.url} className="d-block w-100" alt="poster" />
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>
          </div>
        )}

        {infoModal && (
          <div className="modal-container">
            <p className="modal-container-close" onClick={toggleInfoModal}>
              Close
            </p>
            <div
              className="modal-container-content"
              dangerouslySetInnerHTML={{ __html: `${viewBooth.content}` }}
            ></div>
          </div>
        )}

        {namecardModal && (
          <BoothNamecardModal 
            currentUser={currentUser} 
            hideModal={()=>setNamecardModal(false)} 
            modal={namecardModal} 
            booth={viewBooth} 
          />
        )}

        <div className="container">
          <div className="hall-info">
            <Link to={routes.exhibition1}>Exhibition Hall</Link>
          </div>
        </div>

        <div className="container">
          <div className="nav-wrapper">
            <p className="booth-name">{viewBooth.title}</p>
            <div className="nav-buttons-wrapper">
              {
                viewBooth.order !== 0 && (
                  <img
                    src={chevronLeft}
                    className="nav-button-icon"
                    onClick={goPrevious}
                    alt="prev-icon"
                  />
                )
              }
              {
                viewBooth.order !== booths.length-1 && (
                  <img
                    src={chevronRight}
                    className="nav-button-icon"
                    onClick={goNext}
                    alt="next-icon"
                  />
                )
              }
            </div>
          </div>
        </div>

        <div className="booth-image-wrapper">
          <img src={viewBooth.background_image.url} className="booth-image" alt="booth"/>
        </div>

        {viewBooth ? (
          <div className="container p-0">
            <div className="booth-menu-list">

              <div className="booth-menu-item" onClick={toggleImageModal}>
                <img src={image} className="booth-menu-item-icon" alt="poster-icon"/>
                <p className="booth-menu-item-label">Image Gallery</p>
              </div>
              {
                viewBooth.order !== 0 &&
                <div className="booth-menu-item" onClick={handleVideoClick}>
                  <img src={play} className="booth-menu-item-icon" alt="video-icon"/>
                  <p className="booth-menu-item-label">Video Gallery</p>
                </div>
              }
              {
                viewBooth.order !== 2 &&
                <div className="booth-menu-item" onClick={handlePdfClick}>
                  <img src={pdf} className="booth-menu-item-icon" alt="resource-icon"/>
                  <p className="booth-menu-item-label">PDF Gallery</p>
                </div>
              }
              {
                viewBooth.order === 2 &&
                <div className="booth-menu-item" onClick={openGame}>
                  <svg aria-hidden="true" focusable="false" dataprefix="fas" dataicon="gamepad" className="booth-menu-item-icon svg-inline--fa fa-gamepad fa-w-20" role="img" viewBox="0 0 640 512">
                    <path fill="#492379" d="M480.07 96H160a160 160 0 1 0 114.24 272h91.52A160 160 0 1 0 480.07 96zM248 268a12 12 0 0 1-12 12h-52v52a12 12 0 0 1-12 12h-24a12 12 0 0 1-12-12v-52H84a12 12 0 0 1-12-12v-24a12 12 0 0 1 12-12h52v-52a12 12 0 0 1 12-12h24a12 12 0 0 1 12 12v52h52a12 12 0 0 1 12 12zm216 76a40 40 0 1 1 40-40 40 40 0 0 1-40 40zm64-96a40 40 0 1 1 40-40 40 40 0 0 1-40 40z"></path>
                  </svg>
                  <p className="booth-menu-item-label">Play Game</p>
                </div>
              }

              {
                viewBooth.order !==2 &&
                <div className="booth-menu-item" onClick={handleTawk}>
                  <img src={message} className="booth-menu-item-icon" alt="chat-icon" />
                  <p className="booth-menu-item-label">Talk <br/>To Us</p>
                </div>
              }
              {
                viewBooth.email && (
                  <div className="booth-menu-item" onClick={handleNamecard}>
                    <svg width="28.393" height="23.935" viewBox="0 0 28.393 23.935">
                      <path d="M28.256,18.7a14.586,14.586,0,0,1-2.475-3.636c-.143-.382-.321-.921-.526-1.538-.98-2.954-1.871-5.466-2.859-6.3-1.071-.9-5.208-1.5-6.909-1.719V2.176A2.407,2.407,0,0,0,12.906,0H2.581A2.407,2.407,0,0,0,0,2.176V17.407a2.407,2.407,0,0,0,2.581,2.176H12.906a2.9,2.9,0,0,0,.811-.129,11.365,11.365,0,0,0,1.866,1.115,9.152,9.152,0,0,1,3.863,3.095.675.675,0,0,0,.558.271,9.1,9.1,0,0,0,8.346-4.7A.476.476,0,0,0,28.256,18.7ZM15.487,10.051l2.045.69c.1.3.27.773.44,1.146a4.082,4.082,0,0,0-.217.385,2.946,2.946,0,0,1-.424.662,6.49,6.49,0,0,1-1.843-2.085ZM2.581,18.5a1.2,1.2,0,0,1-1.291-1.088V2.176A1.2,1.2,0,0,1,2.581,1.088H12.906A1.2,1.2,0,0,1,14.2,2.176V8.7A6.631,6.631,0,0,0,10.8,6.313a1.868,1.868,0,0,0-1.579.374c-1.554,1.31-.664,3.536.57,5.045a8.133,8.133,0,0,1,1.825,3.551c.017.152.025.3.033.439a4.177,4.177,0,0,0,1.12,2.773Zm17.783,4.345a10.632,10.632,0,0,0-4.112-3.2,6.885,6.885,0,0,1-2.179-1.464,3.448,3.448,0,0,1-1.132-2.5c-.009-.16-.018-.322-.038-.492a9.009,9.009,0,0,0-2.047-4.065c-1-1.216-1.644-2.879-.72-3.659a.445.445,0,0,1,.409-.078A6.09,6.09,0,0,1,13.6,10c.494,1.031,1.831,3.5,3.577,4.107a.756.756,0,0,0,.606-.05,2.949,2.949,0,0,0,1.155-1.343,3.224,3.224,0,0,0,.145-.294.5.5,0,0,0,.21-.691,10.8,10.8,0,0,1-.6-1.53.588.588,0,0,0-.384-.364l-2.821-.951V6.614c2.345.306,5.389.874,6,1.386.829.7,1.893,3.906,2.529,5.822.209.631.392,1.182.539,1.572A14.311,14.311,0,0,0,27,19.109,7.815,7.815,0,0,1,20.365,22.841Z" fill="#492777"/>
                      <path d="M85.978,42.667a.645.645,0,0,0-.645.645V54.928a.645.645,0,0,0,1.291,0V43.312A.645.645,0,0,0,85.978,42.667Z" transform="translate(-80.171 -40.086)" fill="#492777"/>
                      <path d="M43.311,42.667a.645.645,0,0,0-.645.645v6.453a.645.645,0,1,0,1.291,0V43.312A.645.645,0,0,0,43.311,42.667Z" transform="translate(-40.085 -40.086)" fill="#492777"/>
                    </svg>
                    <p className="booth-menu-item-label">Drop Namecard</p>
                  </div>
                )
              }
            </div>

          </div>
        ) : (
          <div className="load-container">
            <LoadIcon2 />
          </div>
        )}

        {showTawk && (
          <div className="tawk-modal-container">
            <p
              className="modal-container-close"
              onClick={() => {
                setShowTawk(!showTawk)
              }}
            >
              Close
            </p>
            <div className="tawk-modal-container-content">
              <LoadIcon2 />
              <div id="testtesttest"></div>
            </div>
          </div>
        )}
      </div>
    </Page>
  )
}

const Page = styled.div`
  background: white;
  height: calc(100% - 60px);
  width: 100vw;
  overflow-y: scroll;
  border-radius: 0px 0px 25px 25px;
  position: absolute;

  .container {
    width: 90%;
    margin: auto;
  }

  .hall-info {
    font-size: 12px;
    margin: 20px 0px 20px 0px;
    color: black;

    a {
      color: black;
    }
  }

  .nav-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .booth-name {
    width: 70%;
    font-size: 16px;
    margin: 0;
  }

  .nav-buttons-wrapper {
    width: 50px;
    height: 20px;
    display: flex;
    justify-content: space-between;
  }

  .nav-button-icon {
    height: 20px;
  }

  .booth-image-wrapper {
    background-image: url(${boothBg});
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 200px;
  }

  .booth-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .booth-menu-list {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0px 20px 0px;
  }

  .booth-menu-item {
    height: 60px;
    width: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .booth-menu-item-icon {
    height: 25px;
  }

  .booth-menu-item-label {
    font-size: 10px;
    color: #492379;
    margin: 0;
    text-align: center;
  }

  .booth-info-wrapper {
    padding: 20px 0px 20px 0px;
    font-size: 12px;
    border-top: solid 3px #c69dff;
    border-bottom: solid 3px #c69dff;
    margin-bottom: 20px;
    width: 100%;
    overflow-x: scroll;

    p {
      margin-bottom: 0;
      width: 100%;
    }
  }

  .booth-contact-info-title {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .booth-contact-item-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .booth-contact-item-info {
    color: #8669b1;
    font-size: 14px;

    p {
      margin-bottom: 0;
    }
  }

  .booth-contact-item-icon {
    height: 25px;
  }

  .load-container {
    width: 100%;
    height: 100px;
    position: relative;
  }

  .modal-container {
    width: 95%;
    height: 60%;
    border-radius: 30px;
    border: solid 2px #5c628a;
    position: fixed;
    bottom: 1%;
    left: 50%;
    transform: translateX(-50%);
    background: white;
    z-index: 99;
  }

  .modal-container-close {
    font-size: 12px;
    color: #312384;
    font-weight: bold;
    margin-bottom: 0;
    position: absolute;
    top: 20px;
    right: 30px;
  }

  .modal-container-content {
    width: calc(100% - 60px);
    height: calc(100% - 70px);
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    overflow-y: scroll;
    font-size: 12px;
  }

  .modal-content-title {
    font-size: 16px;
    font-style: italic;
    color: #5461a2;
  }

  .modal-content-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .modal-content-label {
    width: 70%;
    margin-bottom: 0;
    margin: 0;
    font-size: 14px;
  }

  .modal-content-button {
    background: #5461a2;
    color: white;
    font-size: 10px;
    border: none;
    border-radius: 20px;
    padding: 5px 20px;
  }

  .modal-content-text {
    font-size: 12px;
  }

  #testtesttest {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .tawk-modal-container {
    width: 100%;
    height: 80%;
    border-radius: 30px;
    border: solid 2px #5c628a;
    position: fixed;
    bottom: 1%;
    left: 50%;
    transform: translateX(-50%);
    background: white;
    z-index: 99;
  }

  .tawk-modal-container-content {
    width: 100%;
    height: calc(100% - 70px);
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    overflow-y: scroll;
    font-size: 12px;
  }

  .iframe-container {
    width: 100%;
    padding-bottom: 56.25%;
    height: 0;
    background: black;
    position: absolute;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  @media screen and (min-width: 540px) {
    .outer-wrapper {
      width: 540px;
      margin: auto;
    }

    .booth-image-wrapper {
      height: 300px;
    }

    .modal-container {
      max-width: calc(0.95 * 540px);
    }

    .tawk-modal-container {
      max-width: 540px;
    }
  }
`

export default MobileExpoBooth
