import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import PhoneInput from 'react-phone-input-2'
import banner from '../../images/registration/event-banner.jpg'
// import title from '../../images/registration/event-title.png'
import BG from '../../images/registration/BG_mobile.jpg'

import { COLORS, SIZES } from '../../styles/theme'
import Axios from 'axios'
import config from '../../config'
import { toast } from 'react-toastify'
import routes from '../../routes'

const Page = styled.div`
  min-height: 100vh;
  background-image: url(${config.assets.registration.landscape});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  padding: 0 1.5em;
  padding-bottom: 4.5em;
  color: #002060;
  img#banner {
    display: none;
  }
  .mobile-show {
    display: none;
  }
  ${SIZES.mobile} {
    background-image: url(${config.assets.registration.portrait});
    padding: 0;
    padding-bottom: 2.5em;
    font-size: 0.9em;
    img#banner {
      display: block;
      width: 90%;
    }
    .mobile-hide {
      display: none;
    }
    .mobile-show {
      display: block;
      /* padding: 1.5rem 0; */
      background-image: url(${BG});
      background-size: cover;
      background-position: bottom;
    }
  }
  
  .row {
    h6 {
      margin: 16px;
      font-weight: 600;
      ${SIZES.mobile} {
        margin: 8px;
      }
    }
    h6.subtitle {
      font-weight: 500;
      margin: 24px 0;
    }
    .register-form {
      .form-group {
        width: 100%;
        margin: 8px auto;
        background: #F8F4FF;
        border: 1px solid #AB8EC0;
        border-radius: 25px;
        font-weight: 600;
        text-align: center;
        padding-right: 16px;
        label {
          color: ${COLORS.primary};
          padding: 0 8px;
          margin: 0 auto;
          padding-right: 0;
          font-size: 13px;
        }
        .required:before {
          content:"* ";
          color: red;
        }
        input[type='tel'],
        .form-input {
          display: block;
          color: #002F62;
          letter-spacing: 1px;
          width: 100%;
          font-weight: 500;
          background: transparent;
          border: none;
          padding: 4px 24px;
          outline: none;
        }
        .react-tel-input {
          color: #279AAC;
          input[type='tel'] {
            padding-left: 56px;
            height: 35px;
            box-shadow: none;
          }
          .country-list {
            margin: 0;
          }
          .flag-dropdown {
            background: transparent;
            border: none;
            padding-left: 16px;
            .selected-flag {
              background: transparent;
            }
          }
        }
      }
      #password {
        width: 12px;
        margin: 2px 4px;
      }
    }
  }

  .form-check {
    display:flex;
    align-items: flex-start;
    .form-check-label {
      color: ${COLORS.primary};
      font-weight: 500;
      font-size: 12px;
      margin-left: 8px;
      a {
        color: #AB8EC0;
        text-decoration: underline;
      }
    }
  }
  
  button.submit-btn {
    background: ${COLORS.primary};
    width: 80%;
    color: #FFF;
    font-weight: 500;
    font-style: italic;
    border: 3px solid #AB8EC0;
    border-radius: 56px;
    display: flex;
    justify-content: space-between;
    padding: 4px 24px;
    margin: 16px auto;
    margin-bottom: 8px;
    font-size: 1em;
    outline: none;
    box-shadow: none;

    ${SIZES.mobile} {
      padding: 4px 24px;
      width: 90%;
    }

    &:disabled {
      cursor: not-allowed;
      background: grey;
      border: solid 1px ${COLORS.primary};
    }

    div {
      line-height: 32px;
    }
    div.arrow {
      line-height: 24px;
      font-size: 1.5em;
      align-self: flex-end;
    }
  }
`

export default function RegisterFormPage() {
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [salutation, setSalutation] = useState('Mr')
  const [fullName, setFullName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('60')
  const [NRIC, setNRIC] = useState('')
  const [password, setPassword] = useState('')
  const [specialty, setSpecialty] = useState("")
  const [MMC, setMMC] = useState('')
  const [others, setOthers] = useState('')
  const [placePractice, setPlacePractice] = useState('')
  const [agree, setAgree] = useState(false)

  const optionList = [
    'Datuk',
    'Dato',
    'Datin',
    'Prof.',
    'Assoc. Prof',
    'Dr',
    'Mr',
    'Ms',
    'Madam',
  ]
  const stateList = [
    'Paediatrician',
    'General Practitioner',
    'Medical Officer',
    'Dietitians',
    'Nutritionists',
    'Family Medicine Specialist',
    'Medical Students',
    'Others',
  ]

  useEffect(() => {
    // history.push(routes.lobby)
    // toast("Registration has been closed.")
    document.title = `${config.eventName} | Registration`
  }, [])
  
  const handleCheckBox = (e) => {
    setAgree(e.target.checked)
  }
  const handleEmailInput = (e) => {
    setEmail(e.target.value.toLowerCase())
  }

  const handleSpecialtyInput = (e) => {
    setSpecialty(e.target.value)
    setMMC("")
    setOthers("")
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    let practiceSpecialty = specialty
    if (specialty === 'Others') {
      practiceSpecialty = others.trim()
    }
    const options = {
      salutation,
      full_name: fullName.trim(),
      email,
      phone,
      nric_number: NRIC.trim(),
      practice_specialty: practiceSpecialty,
      mmc_number: MMC,
      place_of_practice: placePractice,
      password: password.trim(),
    }
    Axios.post(`${config.apiUrl}/api/v1/register`, options)
      .then(() => {
        setLoading(false)
        history.push(routes.postRegister)
      })
      .catch((err) => {
        setLoading(false)
        toast(err.response.data.message)
      })
  }

  const hasEmptyField = !(
    salutation.length &&
    fullName.length &&
    NRIC.length &&
    email.length &&
    phone.length >= 7 &&
    specialty.length &&
    (specialty === 'Others' == others.length > 0) &&
    (["Paediatrician","General Practitioner","Medical Officer","Dietitians"].includes(specialty) == MMC.length > 0) &&
    placePractice.length &&
    agree &&
    password.length >= 8 
  )

  return (
    <Page>
      <div className="mobile-show">
        <img width="100%" src={banner} alt="event-banner" className="mx-auto" />
      </div>
      <Row id="main" noGutters className="px-4 pl-md-0 pt-0 pb-3">

        <Col lg={8} className="px-md-4">
          <img width="100%" src={banner} alt="event-banner" className="mx-auto mobile-hide" />
          <h6 className="subtitle">Kindly fill in your details below to complete registration</h6>
          {hasEmptyField && (
            <p className="text-danger m-0">
              * Please fill in the required field to proceed.
            </p>
          )}
          <Row className="register-form pt-3" noGutters>
            <Col md={6} className="pr-md-4">
              <Form.Group>
                <label className="required">Salutation</label>
                <select
                  className="form-input"
                  value={salutation}
                  onChange={(e) => setSalutation(e.target.value)}
                >
                  {optionList.map((item, i) => (
                    <option key={`option-${i}`} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </Form.Group>
            </Col>
            <Col md={6} className="pr-md-4">
              <Form.Group>
                <label className="required">Full Name (as per NRIC or Passport)</label>
                <input
                  className="form-input"
                  required
                  type="text"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="pr-md-4">
              <Form.Group>
                <label className="required">NRIC No</label>
                <input
                  className="form-input"
                  required
                  type="text"
                  value={NRIC}
                  onChange={(e) => setNRIC(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="pr-md-4">
              <Form.Group>
                <label className="required">Email Address</label>
                <input
                  className="form-input"
                  required
                  type="email"
                  value={email}
                  onChange={handleEmailInput}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="pr-md-4">
              <Form.Group>
                <label className="required">Mobile Number</label>
                <PhoneInput
                  inputProps={{ required: true }}
                  className="form-input"
                  value={phone}
                  onChange={(phone) => setPhone(phone)}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="pr-md-4">
              <Form.Group>
                <label className="required">Password (min 8 characters)</label>
                <input
                  className="form-input"
                  required
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="pr-md-4">
              <Form.Group>
                <label className="required">Practice Specialty</label>
                <select
                  className="form-input"
                  value={specialty}
                  onChange={handleSpecialtyInput}
                >
                  <option className="text-center" value="">
                    -- SELECT --
                  </option>
                  {stateList.map((item, i) => (
                    <option key={`state-${i}`} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </Form.Group>
            </Col>
            <Col md={6} className="pr-md-4">
              {
                ["Paediatrician","General Practitioner","Medical Officer","Dietitians"].includes(specialty) &&
                <Form.Group>
                  <label className="required">MMC No./MDA No.</label>
                  <input
                    className="form-input"
                    required
                    type="text"
                    value={MMC}
                    onChange={(e) => setMMC(e.target.value)}
                  />
                </Form.Group>
              }
              {
                specialty === 'Others' &&
                <Form.Group>
                  <label className="required">Please Specify</label>
                  <input
                    className="form-input"
                    required
                    type="text"
                    value={others}
                    onChange={(e) => setOthers(e.target.value)}
                  />
                </Form.Group>
              }
            </Col>
            <Col md={6} className="pr-md-4">
              <Form.Group>
                <label className="required">Place of Practice / Institute</label>
                <input
                  className="form-input"
                  required
                  type="text"
                  value={placePractice}
                  onChange={(e) => setPlacePractice(e.target.value)}
                />
              </Form.Group>
            </Col>
            
          </Row>
        </Col>
        <Col lg={4} className="align-self-end">
          <div className="form-check">
            <input 
              type="checkbox" 
              id="agree-box" 
              onChange={handleCheckBox}
            />
            <label className="form-check-label">
              <span className="text-danger">*</span>&nbsp;I have read, and fully understand and consent to the collection and processing of my Personal Data in accordance with the&nbsp;
              <a href="https://accucapmscsdnbhd-my.sharepoint.com/:b:/g/personal/winniechang_apxara_com/EVZ4sDX3CPVEgh5OprzmPvUBL5O95pDjeobUUXSxHzRbmQ?e=TTf51I" target="_blank" rel="noopener noreferrer">
                Personal Data and Privacy Notice here
              </a>.
            </label>
          </div>
          <div className="w-100">
            <Button
              type="submit"
              className="submit-btn"
              variant="default"
              disabled={hasEmptyField || loading}
              onClick={handleSubmit}
            >
              <div>{loading ? 'Loading...' : 'SUBMIT'}</div>
              <div className="arrow">⇀</div>
            </Button>
          </div>
        </Col>
      </Row>
    </Page>
  )
}
