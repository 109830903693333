import React, { useState } from 'react'
import styled from 'styled-components'
import { Modal, Button, Form, Col } from 'react-bootstrap'
import { COLORS } from '../styles/theme'
import Axios from 'axios'
import config from '../config'
import { toast } from 'react-toastify'

const Wrapper = styled.div`
  #btn {
    background: none;
    border: none;
    color: ${COLORS.primaryDark};
    font-size: 0.9em;
  }
`

export default function ForgetPassword({ children }) {
  const [show, setShow] = useState(false)
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)

  const handleClose = () => setShow(false)

  const handleClick = (e) => {
    e.preventDefault()
    setShow(true)
  }

  const handleInput = (e) => setEmail(e.target.value)

  const handleSubmit = (e) => {
    setLoading(true)
    e.preventDefault()
    Axios.get(`${config.apiUrl}/api/v1/forget_password`, {
      params: {
        email,
      },
    })
      .then(function (response) {
        setLoading(false)
        if (response.data.exist) {
          toast(
            'We have sent you a password recovery email, please check your mailbox.'
          )
          setShow(false)
          setEmail('')
        } else {
          toast('This email does not seem to be registered to our system.')
        }
      })
      .catch(function (error) {
        setLoading(false)
        toast(error.response.data.message)
      })
  }

  return (
    <Wrapper>
      <button id="btn" onClick={handleClick}>
        {children}
      </button>
      <Modal
        show={show}
        onHide={handleClose}
        size="md"
        aria-labelledby="schedule-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="schedule-modal">Forgot Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Row>
            <Form.Group as={Col} controlId="formGridEmail">
              <Form.Label>Enter Email to reset Password</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email here..."
                onChange={handleInput}
                value={email}
              />
            </Form.Group>
          </Form.Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={email.length < 4 || loading}
            variant="default"
            style={{ color: 'white', background: COLORS.primary, borderRadius: '18px' }}
            onClick={handleSubmit}
          >
            {loading ? 'Verifying...' : 'Verify Email'}
          </Button>
        </Modal.Footer>
      </Modal>
    </Wrapper>
  )
}
