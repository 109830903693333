import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import config from '../config'
import routes from '../routes'
import { COLORS, SIZES } from '../styles/theme'

const Page = styled.div`
  background: url(${config.assets.entrance.landscape}) no-repeat center center
    fixed;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  overflow: hidden;

  #enter-btn {
    position: fixed;
    bottom: 100px;
    right: 150px;
    width: 280px;
    color: #FFFFFF;
    font-weight: 300;
    letter-spacing: 1px;
    background-color: ${COLORS.primary};
    padding: 12px 20px;
    font-size: 20px;
    border: 3px solid #AB8EC0;
    border-radius: 40px;
    font-style: italic;
    display: flex;
    justify-content: space-between;
    div {
      line-height: 32px;
    }
    div.arrow {
      line-height: 24px;
      font-size: 1.5em;
      align-self: flex-end;
    }
  }

  ${SIZES.mobile} {
    display: flex;
    justify-content: center;
    align-items: center;

    #enter-btn {
      position: static;
    }
  }
`

export default function WelcomePage() {
  return (
    <Page>
      <Link id="enter-btn" to={routes.lobby} className="btn">
        <div>ENTER EVENT</div>
        <div className="arrow">⇀</div>
      </Link>
    </Page>
  )
}
