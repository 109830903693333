import LogoL from './images/logo.png'
import Entrance from './images/backdrops/entrance.jpg'
import EntranceM from './images/backdrops/entranceP.jpg'
import LobbyL from './images/backdrops/lobby.jpg'
import Exhibition from './images/backdrops/exhibition-hall.jpg'
import Auditorium from './images/backdrops/auditorium.jpg'
import AuditoriumM from './images/backdrops/auditoriumP.jpg'
import MC from './images/backdrops/MC20.png'
import NetworkingL from './images/backdrops/networking.jpg'
import LobbyMusic from './images/audio/lobby.mp3'
import routes from './routes'
import Gallerium from './images/backdrops/gallerium.jpg'
import Register from './images/registration/registration.jpg'
import RegisterM from './images/registration/registrationP.jpg'

const dev = {
  apiUrl: 'http://localhost:3000',
}

const prod = {
  apiUrl: 'https://danone-dumex.herokuapp.com',
}

const API_KEYS = {
  amplitude: 'f3a6533b0bcfe46e9f286fb4464fd1b6',
}
const eventDays = [11,12,13,14,15]

const config = process.env.NODE_ENV === 'production' ? prod : dev

const assets = {
  logo: {
    landscape: LogoL,
    portrait: LogoL,
  },
  registration: {
    landscape: Register,
    portrait: RegisterM,
  },
  entrance: {
    landscape: Entrance,
    portrait: EntranceM,
  },
  lobby: {
    landscape: LobbyL,
    portrait: EntranceM,
  },
  auditorium1: {
    landscape: Auditorium,
    portrait: AuditoriumM,
  },
  auditorium2: MC,

  exhibition1: Exhibition,
  exhibition2: Exhibition,

  networking: {
    landscape: NetworkingL,
    portrait: NetworkingL,
  },
  gallerium: Gallerium,

  lobbyMusic: LobbyMusic,
}

const layout = {
  auditoriumVid: `
    top: 10%;
    bottom: 28.5%;
    left: 19%;
    right: 19%;
  `,
}

const lobbyEntries = [
  {
    id: 'auditorium',
    destination: routes.auditorium1,
    css: `
      top: 61%;
      left: 34.5%;
      right: 60.5%;
      bottom: 39%;
    `,
  },
  {
    id: 'exhibition',
    destination: routes.exhibition1,
    css: `
      top: 61%;
      left: 73.4%;
      right: 19.5%;
      bottom: 39.5%;
    `,
  },
  {
    id: 'helpdesk',
    destination: 0,
    css: `
      top: 80.5%;
      left: 6.5%;
      right: 91%;
      bottom: 19.5%;
    `,
  },
]

export default {
  API_KEYS,
  assets,
  layout,
  lobbyEntries,
  eventDays,

  eventName: 'Growth Virtual Event',
  // googleAnalyticsID: 'UA-185128388-1',

  ...config,
}
