import React, { useEffect } from 'react'
import styled from 'styled-components'
import { COLORS, SIZES } from '../../styles/theme'
import config from '../../config'
import banner from '../../images/registration/event-banner.jpg'
import logo from '../../images/registration/event-logo.png'
import { useHistory } from 'react-router'
import routes from '../../routes'
import { toast } from 'react-toastify'

const Page = styled.div`
  min-height: 100vh;
  background-image: url(${config.assets.registration.landscape});
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  color: ${COLORS.primary};
  padding: 0 15em;
  padding-bottom: 4.5em;

  #message {
    width: 90%;
    display: flex;
    margin: 0 auto;
    align-items: center;
    padding: 1rem;
    text-align: left;
    .icon {
      width: 100px;
      margin: 0 auto;
    }
    h5 {
      font-weight: 600;
      font-size: 24px;
      margin-left: 16px;
    }
  }
  .mobile-show {
    display: none;
  }
  ${SIZES.mobile} {
    padding: 0;
    padding-bottom: 2em;
    background-image: url(${config.assets.registration.portrait});
    .mobile-hide {
      display: none;
    }
    .mobile-show {
      display: block;
      margin: 0 auto;
    }
    #message {
      flex-direction: column;
      width: 100%;
      padding: 1rem 4rem;
      text-align: center;
      h5 {
        font-size: 1.25em;
        margin-left: 0;
      }
    }
  }
  
`

export default function PostRegisterPage() {
  const history = useHistory()

  useEffect(() => {
    // history.push(routes.lobby)
    // toast("Registration has been closed.")
    document.title = `${config.eventName} | Registration`
  }, [])
  
  return (
    <Page>
      <div>
        <img width="100%" src={banner} alt="event-banner" className="mx-auto" />
        <div id="message" className="mt-5">
          <div className="icon">
            <svg width="92.936" height="92.936" viewBox="0 0 92.936 92.936">
              <path d="M48.468,2A46.468,46.468,0,1,0,94.936,48.468,46.485,46.485,0,0,0,48.468,2Zm0,83.643A37.174,37.174,0,1,1,85.643,48.468,37.224,37.224,0,0,1,48.468,85.643ZM66.5,31.228,39.174,58.552l-8.736-8.736a4.633,4.633,0,0,0-6.552,6.552L35.922,68.4a4.628,4.628,0,0,0,6.552,0L73.1,37.78a4.628,4.628,0,0,0,0-6.552,4.684,4.684,0,0,0-6.6,0Z" transform="translate(-2 -2)" fill="#ab8ec0"/>
            </svg>
          </div>
          <h5>
            Thank you for your registration and you will receive a confirmation email within 3 days after your registration.
          </h5>
        </div>
        <h6 className="text-center mt-2 px-5">
          <em>
            Note: Please check your junk mail in the event if you have not received the link / email in your inbox.
          </em>
        </h6>
        {/* <img className="mobile-show" src={logo} width="50%" alt="event-logo" /> */}
      </div>
    </Page>
  )
}
