import React from 'react'
import { Modal } from 'react-bootstrap'
import { COLORS } from '../../styles/theme'
import styled from 'styled-components'

const StyledModal = styled(Modal)`
  .modal-content {
    /* hardcoded for tawk.to default widget size */
    width: 350px;
    box-shadow: 0px 4px 12px ${COLORS.lightGray1};
  }
  .modal-body {
    padding: 0;
  }
  .tawk-container {
    width: 100%;
  }
`

export default function TawkModal({ embedId, tawkOpen, setTawkOpen }) {
  return (
    <StyledModal
      show={tawkOpen}
      onHide={() => setTawkOpen(false)}
      aria-labelledby="video-modal"
      centered
    >
      <Modal.Body>
        <div className="tawk-container" id={embedId}></div>
      </Modal.Body>
    </StyledModal>
  )
}
