import danone1 from '../images/booth/danone/1.jpg'
import danone2 from '../images/booth/danone/2.jpg'
import danone3 from '../images/booth/danone/3.jpg'
import danone4 from '../images/booth/danone/4.jpg'
import danone5 from '../images/booth/danone/5.jpg'
import danone6 from '../images/booth/danone/6.jpg'
import danone7 from '../images/booth/danone/7.jpg'
import danone8 from '../images/booth/danone/8.jpg'

const BoothImages = [
  { url: danone1 } ,
  { url: danone2 } ,
  { url: danone3 } ,
  { url: danone4 } ,
  { url: danone5 } ,
  { url: danone6 } ,
  { url: danone7 } ,
  { url: danone8 } ,
]
export default BoothImages;