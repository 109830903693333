import React, { useState } from 'react'
import styled from 'styled-components'
// import amplitude from 'amplitude-js'
import config from '../config'
import routes from '../routes'
import { SIZES } from '../styles/theme'
import { Link } from 'react-router-dom'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

import logo from '../images/mcp_logo.png'
import PageWithBg from '../components/PageWithBg'
import backIcon from '../images/icons/back.png'
import slide1 from '../images/gallerium/posterGallery/Slide1.JPG'
import slide2 from '../images/gallerium/posterGallery/Slide2.JPG'
import slide3 from '../images/gallerium/posterGallery/Slide3.JPG'
import slide4 from '../images/gallerium/posterGallery/Slide4.JPG'
import slide5 from '../images/gallerium/posterGallery/Slide5.JPG'
import slide6 from '../images/gallerium/posterGallery/Slide6.JPG'
import InfographicModal from '../components/InfograpicModal'

const Page = styled.div`
  .full {
    position: absolute;
    padding-top: ${SIZES.navHeight};
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    display: flex;

    .sidenavs {
      height: 100%;
      width: 25%;
      display: flex;
      flex-direction: column;
      text-align: center;
      color: white;
    }
    .main {
      width: 75%;
      /* backdrop-filter: blur(15px); */
      background-color: rgba(255, 255, 255, 0.7);
      padding: 32px 56px;
      padding-right: 72px;
      height: 100%;
      overflow-y: scroll;

      .listing {
        margin-top: 36px;

        .infographic {
          background: white;
          width: 175px;
          /* height: 175px; */
          display: flex;
          align-items: flex-start;
          justify-content: center;
          margin-right: 16px;
          margin-bottom: 32px;
          cursor: pointer;
        }
      }
      .toggle-back {
        font-weight: bold;
        color: #171717;
        background: transparent;
        border: none;
        display: flex;
        align-items: center;
        text-decoration: none;
        .back-prev {
          display: flex;
          width: 30px;
          flex-direction: column;
          transition: 1s;
          img {
            width: 26px;
          }
          .icon-label {
            text-align: left;
            font-size: 1px;
            opacity: 0;
          }
          &:hover {
            img {
              transform: scale(0.6);
            }
            .icon-label {
              font-size: 8px;
              opacity: 1;
            }
          }
        }

        span {
          font-size: 24px;
          font-style: italic;
          margin-left: 1em;
        }
      }
    }
  }
`

export default function PosterGalleryPage({ currentUser }) {
  const [posterOpen, setPosterOpen] = useState(false)
  const [content, setContent] = useState('')

  const hidePoster = () => setPosterOpen(false)

  const showPoster = (image) => {
    setContent(image)
    setPosterOpen(true)
  }
 
  return (
    <Page>
      <PageWithBg bgImg={config.assets.gallerium}>
        <section className="full">
          <div className="sidenavs">
            <img
              src={logo}
              className="d-block mx-auto mt-5 mb-3"
              width="80%"
              alt="kwsp logo"
            />
            
            {/* <img
              src={gallery.icon}
              className="d-block mx-auto"
              width="50%"
              alt="gallery icon"
            />
            <h5>{gallery.name}</h5> */}
          </div>
          <div className="main">
            <Link to={routes.lobby} className="toggle-back">
              <div className="back-prev">
                <img src={backIcon} alt="back-icon" />
                <small className="icon-label">Back</small>
              </div>
              <span>E Poster Gallery</span>
            </Link>
            <div className="d-flex flex-wrap listing">
              {[slide1,slide2,slide3,slide4,slide5,slide6].map((item, index) =>
                <LazyLoadComponent key={`poster-${index}`}>
                  <div
                    className="infographic"
                    onClick={() => showPoster(item)}
                  >
                    <img
                      src={item}
                      width="100%"
                      alt={`poster-${index}`}
                    />
                  </div>
                </LazyLoadComponent>
              )}
            </div>
          </div>
        </section>
      </PageWithBg>
      <InfographicModal
        posterOpen={posterOpen}
        hidePoster={hidePoster}
        content={content}
      />
    </Page>
  )
}
