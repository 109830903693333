import { useObject } from 'react-firebase-hooks/database'
import { firebaseDb } from '../firebaseConfig'

function handleDeviceSession(uid) {
  const sessionToken = Date.now().toString()
  const sessionRef =
    process.env.REACT_APP_STAGE === 'production'
      ? `session/${uid}`
      : `session/local/${uid}`
  firebaseDb.ref(sessionRef).set(sessionToken)
  localStorage.setItem('session_token', sessionToken)
}

function useDuplicateSession(currentUser) {
  const sessionRef =
    process.env.REACT_APP_STAGE === 'production'
      ? `session/${currentUser?.id}`
      : `session/local/${currentUser?.id}`

  const [snapshot, loading, error] = useObject(firebaseDb.ref(sessionRef))

  if (!loading && !error && snapshot.val()) {
    return snapshot.val() !== localStorage.getItem('session_token')
  }

  return false
}

export { handleDeviceSession, useDuplicateSession }
