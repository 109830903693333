import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyDPzw8BU3fjqb-eQDXmZeImDBL0d8c6rto",
  authDomain: "danone-dumex.firebaseapp.com",
  databaseURL: "https://danone-dumex-default-rtdb.firebaseio.com",
  projectId: "danone-dumex",
  storageBucket: "danone-dumex.appspot.com",
  messagingSenderId: "1005306792701",
  appId: "1:1005306792701:web:de7da4274b4d2b7bbe4c94",
  // measurementId: 'G-MYCN9XLCDX',
}

firebase.initializeApp(firebaseConfig)

const firebaseDb = firebase.database()
const firebaseAuth = firebase.auth()

const ChatDb = firebaseDb.ref('chat')
const ChatAudDb = firebaseDb.ref('chatAuditorium')
const NoticeDb = firebaseDb.ref('notice')
const AccessDb = firebaseDb.ref('access')
const NotificationDb = firebaseDb.ref('pushNotifications')

export {
  firebaseDb,
  firebaseAuth,
  ChatDb,
  ChatAudDb,
  NoticeDb,
  NotificationDb,
  AccessDb,
}
